import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpRequest, HttpHeaders } from '@angular/common/http';
import { finalize, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HttpInterceptorService {

  constructor() { }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    var token = localStorage.getItem('bteamsToken')
    if (token) {
      const authReq = request.clone({
        headers: new HttpHeaders({
          // 'Authorization': 'Bearer ' + token

          Token:token
        })
      });
      return next.handle(authReq);
    }
    else {
      const authReq = request.clone({
      });
     
      return next.handle(authReq);
    }
  }
}
