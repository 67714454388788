import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/common/interceptor/api-service/api.service';
import { MessagehandlingService } from 'src/app/common/interceptor/message/messagehandling.service';
import { ConvertcsvjsonService } from 'src/app/common/services/convertcsvjson.service';
import { environment } from 'src/environments/environment';
import { AddContentComponent } from '../add-content/add-content.component';
import { ChoosefiletypeComponent } from '../uploadfile/choosefiletype/choosefiletype.component';

@Component({
  selector: 'app-edit-uploadfile',
  templateUrl: './edit-uploadfile.component.html',
  styleUrls: ['./edit-uploadfile.component.scss']
})
export class EditUploadfileComponent {
  highlyttoggle: boolean = false;
  currentRoute: any;
  selectedSocialInfo: any;
  selectedMemberCount: any;
  imageBaseUrl: any
  profileIconArrayLength: any
  userData: any;
  userInfo: any;
  linkForm: FormGroup
  fileNme: any;
  profileData: any=[]
  selectedProfileData: any = [];
  selectedProfileCount: any = 0;
  selectAllCheckbox: boolean = false;
  profileImageUrl: any;
  searchText: any
  constructor(public service: ConvertcsvjsonService, private router: Router, public dialog: MatDialog, private fb: FormBuilder, private api: ApiService, private message: MessagehandlingService, public dialogRef: MatDialogRef<EditUploadfileComponent>, @Inject(MAT_DIALOG_DATA) public data: { block: any },) {
    if (this.data) {
      sessionStorage.setItem('linkData', JSON.stringify(this.data.block))
    }
    let data1: any = sessionStorage.getItem('linkData')
    let data2 = JSON.parse(data1)
    this.linkForm = this.fb.group({
      // highlight: '',
      title: data2.title,
      file_name: data2.file_name,
      //  name:''
      // active: '',
      // country_code: '91',
      // dial_code: '',
      // type:'',
      // slug:'whatsapp',

    })
    this.fileNme = data2.file_name
  }

  ngOnInit(): void {
    this.getMemberData()
    this.profileImageUrl = environment.userImage
    let file = this.service.getImage()
    if (file) {
      this.fileNme = file.name
    }
    let data1: any = sessionStorage.getItem('linkData')
    let data2 = JSON.parse(data1)
    this.selectedSocialInfo = data2;
    this.selectedMemberCount = Number(sessionStorage.getItem('SelectedMembersCount'));
    this.imageBaseUrl = this.api.baseSocialImageUrl
    this.userData = (sessionStorage.getItem('userinformation'))
    this.userInfo = JSON.parse(this.userData)
  }

  closeDialog() {
    this.dialogRef.close();
  }


  // getProfileList() {
  //   let formData = new FormData();
  //   formData.append('user_id', sessionStorage.getItem('SelectedMembersId') || '')
  //   this.api.profileList(formData).subscribe((response: any) => {
  //     if (response.success == 1) {
  //       this.profileData = response.items
  //     }
  //   })
  // }

  getMemberData() {
    let form = new FormData
    this.api.userTeamList(form).subscribe((res: any) => {
      res.items.forEach((element:any) => {
        if(element.member_status!=4){
        this.profileData.push(element)
        }
      });
    })
  }


  stopClose(e: any) {
    e.stopPropagation();
  }

  closeFilter() {
    if (this.selectedProfileData != '') {
      let fd = new FormData
      let file = this.service.getImage()
      // fd.append('user_id', sessionStorage.getItem('SelectedMembersId') || '')
      fd.append('user_id', this.selectedProfileData)
      fd.append('type', 'primary')
      fd.append('title', this.linkForm.value.title)
      fd.append('file_name', file)
      fd.append('slug', this.selectedSocialInfo?.slug)
      this.api.saveLinksForMultipleProfile(fd).subscribe((res: any) => {
        this.message.sucessMessage(res.msg, 1000)
        $('.filter_dropdown .dropdown-menu, .exportesv .dropdown-menu').removeClass('show');
        this.dialogRef.close()
      })
    }
  }


  checkMat(event: any, index: number, data: any) {
    this.profileData[index].checked = event.checked;
    if (event.checked == true) {
      this.selectedProfileCount = this.selectedProfileCount + 1;
      this.selectedProfileData.push(data.user_id)
      if (this.selectedProfileCount == this.profileData.length) {
        this.selectAllCheckbox = true;
      }
    }
    else {
      this.selectedProfileCount = this.selectedProfileCount - 1;
      this.selectAllCheckbox = false;

      this.selectedProfileData.forEach((element: any, index: number) => {
        if (element == data.user_id) {
          this.selectedProfileData.splice(index, 1)
        }
      })
    }
  }

  selectAllProfile(event: any) {
    this.selectedProfileData = []
    if (event.checked == true) {
      this.profileData.forEach((res: any, index: number) => {
        this.selectedProfileData.push(res.user_id)
        this.profileData[index].checked = true;
        this.selectedProfileCount = this.profileData.length;
      });
    }
    else {
      this.profileData.forEach((res: any, index: number) => {
        this.profileData[index].checked = false;
        this.selectAllCheckbox = false;
        if (this.selectedProfileCount == this.profileData.length) {
          this.selectedProfileData = []
        }
        this.selectedProfileCount = 0;
      });
    }
  }

  backToAddContent(): void {
    this.dialogRef.close();
  }

  highlightbtn(event: any) {
    this.highlyttoggle = event.checked;
  }

  deleteIcon(id: any, slug: any) {
    let formData = new FormData()
    formData.append('id', id)
    formData.append('slug', slug)
    this.api.deleteLink(formData).subscribe((res: any) => {
      this.message.sucessMessage(`${this.selectedSocialInfo?.slug} deleted successfully`, 2000)
      this.dialog.closeAll();
    })
  }


  fileType(): void {
    this.dialogRef.close();
    this.dialog.open(ChoosefiletypeComponent, {
      panelClass: ['cmn_modal', 'modal_sm'],
      width: '390px',
      data: {
        route: 'edit'
      }
    });
  }


  submitForm() {
    if (
      !this.linkForm.get('title')?.value
      
    ) {
      this.message.errorMessage('Please fill in all required fields.', 2000);
      return;
    } else {
    let formData = new FormData();
    let file = this.service.getImage()
    formData.append('id', this.selectedSocialInfo.id)
    formData.append('title', this.linkForm.value.title)
    formData.append('file_name', file)
    formData.append('slug', this.selectedSocialInfo?.slug)
    formData.append('active', '')
    formData.append('type', 'primary')
    formData.append('user_id', sessionStorage.getItem('SelectedMembersId') || '')
    formData.append('profile_id', sessionStorage.getItem('SelectedContactId') || '')
    this.api.updateLink(formData).subscribe((res: any) => {
      this.message.sucessMessage(`${this.selectedSocialInfo?.slug} successfully updated to card`, 2000)
      this.dialog.closeAll();
    })
    const currentRoute = this.router.url;
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([currentRoute]); // navigate to same route
    });
  }
  }
}
