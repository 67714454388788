import { Component } from '@angular/core';

@Component({
  selector: 'app-niha-subscription',
  templateUrl: './niha-subscription.component.html',
  styleUrls: ['./niha-subscription.component.scss']
})
export class NihaSubscriptionComponent {
  yearly: boolean = false
  monthly: boolean = true
  plan: any
  paymentHandler: any = null;


  ngOnInit() {
    this.invokeStripe();
  }

  checkPlan(data: any) {
    if (data.checked == false) {
      this.monthly = true
      this.yearly = false
    }
    else if (data.checked == true) {
      this.yearly = true
      this.monthly = false
    }

  }

  bookDemo() {
    window.open('https://calendly.com/nihaio/30min')
  }


  buyPlan(feature: any, timeDuration: any) {
    if (timeDuration == true) {
      this.plan = 'monthly'
      console.log(this.plan, feature, "IF");
      if (feature === 'Pro') {
        this.makePayment(6)
      } else {
        this.makePayment(9)
      }
    }
    else {
      this.plan = 'yearly'
      console.log(this.plan, feature, "else");
      if (feature === 'Pro') {
        this.makePayment(60)
      } else {
        this.makePayment(90)
      }
    }
  }


  makePayment(amount: any) {
    if (!this.paymentHandler) {
      console.error('Stripe checkout is not initialized.');
      return;
    }

    this.paymentHandler.open({
      name: 'Niha-teams',
      description: 'Subscription Payment',
      amount: amount * 100,
    });
  }


  invokeStripe() {
    if (!window.document.getElementById('stripe-script')) {
      const script = window.document.createElement('script');
      script.id = 'stripe-script';
      script.type = 'text/javascript';
      script.src = 'https://checkout.stripe.com/checkout.js';
      script.onload = () => {
        this.paymentHandler = (<any>window).StripeCheckout.configure({
          key: 'pk_test_51HMZUWJ7FnNkVhR8EXLnCc2faetLy8MbCstUIHwg5U2mvp0qoHqe70ggXH9szcPLIbbVrXv9XrzM7sL0YUk35hpP00yYZKBm5n',
          locale: 'auto',
          token: function (stripeToken: any) {
            console.log(stripeToken);
            alert('Payment has been successful!');
          },
          opened: function () {
            const stripeModal = document.querySelector('.stripe_checkout_modal');
            if (stripeModal instanceof HTMLElement) {
              stripeModal.style.width = '80%';
              stripeModal.style.height = '80%';
            }
          }
        });
      };
      window.document.body.appendChild(script);
    }
  }


  ngOnDestroy() {
    this.paymentHandler.destroy();
  }

  invokedStripe() {
    window.document.getElementById('stripe-script')
    const script = window.document.createElement('script')
    script.id = 'stripe-script',
      script.type = 'text/javascript',
      script.src = 'https://checkout.stripe.com/checkout.js';
    script.onload = () => {
      this.paymentHandler = (<any>window).StripeCheckout.configure({
        key: '',
        locale: 'auto',
        token: function (stripeToken: any) {
          console.log(stripeToken);
          alert('Payment has been successful!')

        },
        opened: function () {
          const stripModal = document.querySelector('.stripe-checkout-model')
        }
      })
    }
  }

}


