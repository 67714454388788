
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NavigationEnd, Router } from '@angular/router';
import { ApiService } from 'src/app/common/interceptor/api-service/api.service';
import { MessagehandlingService } from 'src/app/common/interceptor/message/messagehandling.service';
import { AddContentComponent } from '../add-content/add-content.component';

@Component({
  selector: 'app-add-link',
  templateUrl: './add-link.component.html',
  styleUrls: ['./add-link.component.scss']
})
export class AddLinkComponent implements OnInit {
  highlyttoggle: boolean = false;
  // added on 16 nov 2022 start
  currentRoute: any = '';
  selectedSocialInfo: any = {};
  selectedMemberCount: number = 0;
  imageBaseUrl: any;
  // added on 25 nov 2022 start
  addLinkForm: FormGroup;
  profileIconArrayLength: any
  userInfo: any = {}
  userData: any
  currentPath: any
  constructor(public dialog: MatDialog, private router: Router, public dialogRef: MatDialogRef<AddLinkComponent>, @Inject(MAT_DIALOG_DATA) public data: { block: any }, private fb: FormBuilder, private api: ApiService, private message: MessagehandlingService) {

    this.currentRoute = window.location.pathname
    this.addLinkForm = this.fb.group({
      highlight: '',
      content: '',
      title: this.data.block.name,
      description: '',
      custom_icon: '',
      active: '',
      country_code: '',
      dial_code: ''
    })
  }



  ngOnInit(): void {
    this.selectedSocialInfo = this.data.block;
    this.selectedMemberCount = Number(sessionStorage.getItem('SelectedMembersCount'));
    this.imageBaseUrl = this.api.baseSocialImageUrl
    this.userData = (sessionStorage.getItem('userinformation'))
    this.userInfo = JSON.parse(this.userData)
  }

  closeDialog() {
    this.dialogRef.close();
  }

  backToAddContent(): void {
    this.dialogRef.close();
    this.dialog.open(AddContentComponent, {
      panelClass: ['cmn_modal', 'modal_lg'],
      width: '1066px',
    });
  }

  highlightbtn(event: any) {
    this.highlyttoggle = event.checked;
  }


  hasEmptyRequiredFields(formValues: any): boolean {
    return (
      !formValues.content ||
      !formValues.title
    );
  }

  submitForm() {

    const formValues = this.addLinkForm.value;
    if (window.location.pathname === '/profile' || window.location.pathname === '/profile/content') {
      this.profileIconArrayLength = sessionStorage.getItem('profileIconArrayLength');
      const hasEmptyFields = this.hasEmptyRequiredFields(formValues);

      if (hasEmptyFields) {
        this.message.errorMessage('Please fill in all required fields.', 2000);
        return;
      }

      // this.profileIconArrayLength = sessionStorage.getItem('profileIconArrayLength')
      let formData = new FormData();
      formData.append('highlight', this.addLinkForm.value.highlight == '' || this.addLinkForm.value.highlight == false ? '0' : '1')
      formData.append('content', this.addLinkForm.value.content)
      formData.append(' row_order', this.profileIconArrayLength + 1)
      formData.append('title', this.addLinkForm.value.title)
      formData.append('description', this.addLinkForm.value.highlight == true ? this.addLinkForm.value.description : '')
      formData.append('custom_icon', this.addLinkForm.value.custom_icon)
      formData.append('country_code', this.addLinkForm.value.country_code)
      formData.append('dial_code', this.addLinkForm.value.dial_code)
      formData.append('slug', this.selectedSocialInfo?.slug)
      formData.append('active', '')
      formData.append('type', 'primary')
      formData.append('user_id', sessionStorage.getItem('SelectedMembersId') || '')
      formData.append('profile_id', sessionStorage.getItem('SelectedContactId') || '')
      this.updateLink(formData)
      // formData.forEach((val, key) => {
      // })
    }
    if (window.location.pathname === '/members' || this.currentPath) {
      if (this.selectedMemberCount > 0) {
        const hasEmptyFields = this.hasEmptyRequiredFields(formValues);

        if (hasEmptyFields) {
          this.message.errorMessage('Please fill in all required fields.', 2000);
          return;
        }
        let formData = new FormData
        formData.append('highlight', this.addLinkForm.value.highlight == '' || this.addLinkForm.value.highlight == false ? '0' : '1')
        formData.append('content', this.addLinkForm.value.content)
        formData.append(' row_order', this.profileIconArrayLength + 1)
        formData.append('title', this.addLinkForm.value.title)
        formData.append('description', this.addLinkForm.value.highlight == true ? this.addLinkForm.value.description : '')
        formData.append('custom_icon', this.addLinkForm.value.custom_icon)
        formData.append('country_code', this.addLinkForm.value.country_code)
        formData.append('dial_code', this.addLinkForm.value.dial_code)
        formData.append('slug', this.selectedSocialInfo?.slug)
        formData.append('active', '')
        formData.append('type', 'primary')
        formData.append('user_id', sessionStorage.getItem('userIdArray') || '')
        this.api.saveLinksForMultipleProfile(formData).subscribe((res: any) => {
          this.dialog.closeAll()
          this.message.sucessMessage(res.msg, 2000)
          this.selectedMemberCount == 0
        })
      }
    }
    const currentRoute = this.router.url;
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([currentRoute]); // navigate to same route
    });

  }

  updateLink(formData: any) {
    this.api.updateLink(formData).subscribe((res: any) => {
      this.message.sucessMessage(`${this.selectedSocialInfo?.slug} successfully added to card`, 2000)
      this.dialog.closeAll();
      this.router.navigate(['/profile/content'])
    })

  }

  get content() {
    return this.addLinkForm.get('content')!.value
  }

  previewLink() {
    window.open(this.selectedSocialInfo?.base_url + this.content)
  }


}
