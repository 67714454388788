import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/common/interceptor/api-service/api.service';

@Component({
  selector: 'app-notes-preview-link',
  templateUrl: './notes-preview-link.component.html',
  styleUrls: ['./notes-preview-link.component.scss']
})
export class NotesPreviewLinkComponent {
  notesData: any;
  id: any;
  slug: any;

  constructor(private router: Router, public activeroute: ActivatedRoute, private api: ApiService) {
    this.activeroute.params.subscribe((params: any) => {
      this.id = params['id'];
      this.slug = params['slug'];
      if (this.id) {
        this.getDetails()
      } else {
        this.router.navigate(['/']);
      }
    })
  }

  ngOnInit(): void {
  }

  getDetails() {
    const formData = new FormData();
    formData.append('id', this.id);
    formData.append('slug', this.slug);
    this.api.getServiceDetail(formData).subscribe((response: any) => {
      if (response.success == 1) {
        this.notesData = response.item

      }

    })
  }

}



