import { Component } from '@angular/core';
import { AppComponent } from 'src/app/app.component';
import { ApiService } from 'src/app/common/interceptor/api-service/api.service';
import { MessagehandlingService } from 'src/app/common/interceptor/message/messagehandling.service';

@Component({
  selector: 'app-dns',
  templateUrl: './dns.component.html',
  styleUrls: ['./dns.component.scss']
})
export class DnsComponent {
  domainName: any = ''
  userInfo: any;
  userData: any=[]
  constructor(private app: AppComponent, private api: ApiService, private message: MessagehandlingService) { }

  ngOnInit() {
    this.userInfo = JSON.parse(sessionStorage.getItem('userinfo') || '')
    this.app.changeurl('showdrawer')
  }

    getuser() {
      let dt: any = {
        'target_user_id': this.userInfo.user_id
      }
      this.api.aboutLandingApi(dt).subscribe((res: any) => {
        this.userData = res.user
      })
    }

  submitDomain() {
    if (this.domainName == '') {
      alert('Please enter domain name')
    }
    let fd = new FormData();
    let result = this.domainName.replace(/(^\w+:|^)\/\//, '');
    debugger;
    console.log("Original domainName:", this.domainName);
    console.log("Processed result:", result);
    
    fd.append('domain', result.trim())
    this.api.verifyDomain(fd).subscribe((res: any) => {
      console.log(res);
      this.message.sucessMessage(res.msg,2000)
      this.domainName = ''
      this.getuser()
    })


  }

}
