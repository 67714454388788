import { Component } from '@angular/core';

@Component({
  selector: 'app-invite-accepted',
  templateUrl: './invite-accepted.component.html',
  styleUrls: ['./invite-accepted.component.scss']
})
export class InviteAcceptedComponent {

}
