import { Component, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/common/interceptor/api-service/api.service';
import { MessagehandlingService } from 'src/app/common/interceptor/message/messagehandling.service';
@Component({
  selector: 'app-assign-subteam-user',
  templateUrl: './assign-subteam-user.component.html',
  styleUrls: ['./assign-subteam-user.component.scss']
})
export class AssignSubteamUserComponent {
  subteamData: any = []
  userId: any = []
  count: number = 0
  constructor(public dialog: MatDialog, @Inject(MAT_DIALOG_DATA) public data: { block: any }, private api: ApiService, private message: MessagehandlingService, private router: Router) {

  }

  ngOnInit(): void {
    setTimeout(() => {
      this.subteamData = this.data.block
      this.userId = sessionStorage.getItem('userIdArray')
    }, 500)

  }

  addSubteamUser() {
    let formData = new FormData
    formData.append('user_id', this.userId)
    formData.append('sub_team_id', this.subteamData.id)
    this.api.saveSubTeamUser(formData).subscribe((res: any) => {
      this.dialog.closeAll()
      this.message.sucessMessage(res.msg, 2000)
      window.location.reload()
    })
  }

  cancel() {
    this.dialog.closeAll()
  }


}
