import { Component } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AppComponent } from 'src/app/app.component';
import { ApiService } from 'src/app/common/interceptor/api-service/api.service';
import { MessagehandlingService } from 'src/app/common/interceptor/message/messagehandling.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent {
  hide: boolean = true;
  show: boolean = true;
  changePasswordForm: FormGroup;
  email_token:any
  user_id:any
  constructor(private app: AppComponent, public activeroute: ActivatedRoute, private api: ApiService, private router: Router, public message: MessagehandlingService) {
    this.activeroute.params.subscribe((params: any) => {
      this.email_token = params['email_token'];
      this.user_id = params['user_id']
    })
    this.changePasswordForm = new FormGroup({
      password: new FormControl(''),
      confirmPassword: new FormControl('')
    })
  }

  hideShowPassword() {
    this.hide = !this.hide;
  }

  hideConfirmPassword() {
    this.show = !this.show;
  }

  submitData() {
    if (this.changePasswordForm.value.password != this.changePasswordForm.value.confirmPassword) {
      this.message.errorMessage('password and confirm password must be the same.', 1000)
    }
    else {
      let fd = new FormData
      fd.append('password', this.changePasswordForm.value.password)
      fd.append('email_token', this.email_token)
      fd.append('user_id',this.user_id)
      this.api.resetPwd(fd).subscribe((res: any) => {
        this.message.sucessMessage(res.msg, 1000)
        this.router.navigate(['/login'])

      })
    }
  }

}


