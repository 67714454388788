import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/common/interceptor/api-service/api.service';
import { MessagehandlingService } from 'src/app/common/interceptor/message/messagehandling.service';
import { environment } from 'src/environments/environment';
import { AddContentComponent } from '../add-content/add-content.component';

@Component({
  selector: 'app-edit-notes',
  templateUrl: './edit-notes.component.html',
  styleUrls: ['./edit-notes.component.scss']
})
export class EditNotesComponent {
  highlyttoggle: boolean = false;
  selectedSocialInfo: any;
  selectedMemberCount: any;
  imageBaseUrl: any
  profileIconArrayLength: any
  userData: any;
  userInfo: any;
  editNotesForm: FormGroup
  profileData: any=[]
  selectedProfileData: any = [];
   selectedProfileCount: any=0
  selectAllCheckbox: boolean = false;
  profileImageUrl: any;
  searchText: any
  constructor(public dialog: MatDialog, private router: Router, public dialogRef: MatDialogRef<EditNotesComponent>, @Inject(MAT_DIALOG_DATA) public data: { block: any }, private fb: FormBuilder, private api: ApiService, public message: MessagehandlingService) {
    this.editNotesForm = this.fb.group({
      highlight: data.block.highlight == '' || data.block.highlight == '0' ? false : true,
      content: data.block.content,
      title: data.block.title,
      description: data.block.description,
      hints: data.block.hints,

    })
  }

  ngOnInit(): void {
    this.getMemberData()
    this.profileImageUrl = environment.userImage
    this.selectedSocialInfo = this.data.block; 
    this.selectedMemberCount = Number(sessionStorage.getItem('SelectedMembersCount'));
    this.imageBaseUrl = this.api.baseSocialImageUrl
    this.userData = (sessionStorage.getItem('userinformation'))
    this.userInfo = JSON.parse(this.userData)
  }

  closeDialog() {
    this.dialogRef.close();
  }

  // getProfileList() {
  //   let formData = new FormData();
  //   formData.append('user_id', sessionStorage.getItem('SelectedMembersId') || '')
  //   this.api.profileList(formData).subscribe((response: any) => {
  //     if (response.success == 1) {
  //       this.profileData = response.items
  //     }
  //   })
  // }

  getMemberData() {
    let form = new FormData
    this.api.userTeamList(form).subscribe((res: any) => {
      res.items.forEach((element:any) => {
        if(element.member_status!=4){
        this.profileData.push(element)
        }
      });
      // this.profileData = res?.items
    })
  }

  stopClose(e: any) {
    e.stopPropagation();
  }

  closeFilter() {
    if (this.selectedProfileData != '') {
      let fd = new FormData
      // fd.append('user_id', sessionStorage.getItem('SelectedMembersId') || '')
      fd.append('user_id', this.selectedProfileData)
      fd.append('type', 'primary')
      fd.append('highlight', this.editNotesForm.value.highlight == '' || this.editNotesForm.value.highlight == false ? '0' : '1')
      fd.append('content', this.editNotesForm.value.content)
      fd.append('title', this.editNotesForm.value.title)
      fd.append('description', this.editNotesForm.value.highlight == true ? this.editNotesForm.value.description : '')
      fd.append('slug', this.selectedSocialInfo?.slug)
      this.api.saveLinksForMultipleProfile(fd).subscribe((res: any) => {
        this.message.sucessMessage(res.msg, 1000)
        $('.filter_dropdown .dropdown-menu, .exportesv .dropdown-menu').removeClass('show');
        this.dialogRef.close()

      })
    }
  }


  checkMat(event: any, index: number, data: any) {
    this.profileData[index].checked = event.checked;
    if (event.checked == true) {
      this.selectedProfileCount = this.selectedProfileCount + 1;
      this.selectedProfileData.push(data.user_id)
      if (this.selectedProfileCount == this.profileData.length) {
        this.selectAllCheckbox = true;
      }
    }
    else {
      this.selectedProfileCount = this.selectedProfileCount - 1;
      this.selectAllCheckbox = false;

      this.selectedProfileData.forEach((element: any, index: number) => {
        if (element == data.user_id) {
          this.selectedProfileData.splice(index, 1)
        }
      })
    }
  }

  selectAllProfile(event: any) {
    this.selectedProfileData = []
    if (event.checked == true) {
      this.profileData.forEach((res: any, index: number) => {
        this.selectedProfileData.push(res.user_id)

        this.profileData[index].checked = true;
        this.selectedProfileCount = this.profileData.length;
      });
    }
    else {
      this.profileData.forEach((res: any, index: number) => {
        this.profileData[index].checked = false;
        this.selectAllCheckbox = false;
        if (this.selectedProfileCount == this.profileData.length) {
          this.selectedProfileData = []
        }
        this.selectedProfileCount = 0;
      });
    }
  }


  get content() {

    return this.editNotesForm.get('content')!.value
  }

  // previewLink() {
  //   // if (this.data.block?.base_url) {
  //   //   window.open(this.data.block?.base_url + this.content)
  //   // } else {
  //   //   let link: any = this.content.slice(0, 5);
  //   //   if (link == "https" || link == "http:") {
  //   //     window.open(this.content)
  //   //   } else {
  //   //     window.open("https://" + this.content)
  //   //   }

  //   // }
  //   //  
  //   this.router.navigate([`/notes/${btoa(this.selectedSocialInfo?.id)}`])
  // }

  previewLink() {
    const encodedId = btoa(this.selectedSocialInfo?.id);
    const notesRoute = `/notes/${encodedId}`;
    const newWindow = window.open('', '_blank'); // Open a new blank window/tab
    if (newWindow) {
      newWindow.location.href = notesRoute; // Set the URL of the new window/tab
    } else {
      // Handle pop-up blocking or inability to open a new window
      console.error('Unable to open new window.');

    }
  }

  backToAddContent(): void {
    this.dialogRef.close();
  }

  highlightbtn(event: any) {
    this.highlyttoggle = event.checked;
  }

  deleteIcon(id: any, slug: any) {
    let formData = new FormData()
    formData.append('id', id)
    formData.append('slug', slug)
    this.api.deleteLink(formData).subscribe((res: any) => {
      this.message.sucessMessage(`${this.selectedSocialInfo?.slug} deleted successfully`, 2000)
      this.dialog.closeAll();
    })

  }

  submitForm() {
    if (
      !this.editNotesForm.get('title')?.value ||
      !this.editNotesForm.get('content')?.value 
     
    ) {
      this.message.errorMessage('Please fill in all required fields.', 2000);
      return;
    } else {
    let formData = new FormData();
    formData.append('id', this.selectedSocialInfo.id)
    formData.append('highlight', this.editNotesForm.value.highlight == '' || this.editNotesForm.value.highlight == false ? '0' : '1')
    formData.append('content', this.editNotesForm.value.content)
    formData.append('title', this.editNotesForm.value.title)
    formData.append('description', this.editNotesForm.value.highlight == true ? this.editNotesForm.value.description : '')
    formData.append('slug', this.selectedSocialInfo?.slug)
    formData.append('active', '')
    formData.append('type', 'primary')
    formData.append('user_id', sessionStorage.getItem('SelectedMembersId') || '')
    formData.append('profile_id', sessionStorage.getItem('SelectedContactId') || '')
    this.api.updateLink(formData).subscribe((res: any) => {
      this.message.sucessMessage(`${this.selectedSocialInfo?.slug} successfully updated to card`, 2000)
      this.dialog.closeAll();
    })
    const currentRoute = this.router.url;
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([currentRoute]); // navigate to same route
    });
   }
  }


}
