import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { NihaSubscriptionComponent } from './niha-subscription.component';
import { NihaSubscriptionRoutingModule } from './niha-subscription-routing.module';
import { AppComponent } from 'src/app/app.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

@NgModule({
  declarations: [
   NihaSubscriptionComponent
  ],
  imports: [
   NihaSubscriptionRoutingModule,
   MatSlideToggleModule
        // ProfileModule
        
  ],
  exports: [NihaSubscriptionComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
   providers:[AppComponent]
})
export class NihaSubscriptionModule { }
