
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy
} from '@angular/core';
import {MatCalendar} from '@angular/material/datepicker';
import {DateAdapter, MAT_DATE_FORMATS, MatDateFormats, MAT_DATE_LOCALE} from '@angular/material/core';

import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import * as _moment from 'moment';
import {default as _rollupMoment} from 'moment';

const moment = _rollupMoment || _moment;
import {FormControl} from '@angular/forms';


@Component({
  selector: 'app-dateheader',
  templateUrl: './dateheader.component.html',
  styleUrls: ['./dateheader.component.scss']
})
// export class DatepickerCustomHeaderExample {
//   exampleHeader = ExampleHeader;
//     date = new FormControl(moment());

// }
export class DateheaderComponent<D> {
  private _destroyed = new Subject<void>();
  

  constructor(
    private _calendar: MatCalendar<D>, private _dateAdapter: DateAdapter<D>,
    @Inject(MAT_DATE_FORMATS) private _dateFormats: MatDateFormats, cdr: ChangeDetectorRef) {
  _calendar.stateChanges
      .pipe(takeUntil(this._destroyed))
      .subscribe(() => cdr.markForCheck());
}

ngOnDestroy() {
  this._destroyed.next();
  this._destroyed.complete();
}

get periodLabel() {
  return this._dateAdapter
      .format(this._calendar.activeDate,this._dateFormats.display.monthYearLabel)
      .toLocaleUpperCase();
}

previousClicked(mode: any) {
  this._calendar.activeDate = this._dateAdapter.addCalendarMonths(this._calendar.activeDate, -1);
}

nextClicked(mode: any) {
  this._calendar.activeDate =  this._dateAdapter.addCalendarMonths(this._calendar.activeDate, 1);
}
}


