<div class="landing_block" id="pricing">
    <section class="hero-area">
        <div class="container header_block ">
            <div class="row justify-content-center">
                <div class="col-md-10">
                    <h1 class="mb-4">Choose the best plan for you or your business.</h1>
                    <p class="text-center mb-4"> Use the toggle below to switch between individual or team pricing plans
                    </p>
                </div>
                <div class="text-center d-flex align-items-center gap-2 justify-content-center pt-4">
                    <!-- <div id="planToggle" class="plan-toggle individual">
                            <button id="individualButton" class="plan-button" (click)="checkPlan('individualButton')">Individuals</button>
                            <button id="teamsButton" class="plan-button" (click)="checkPlan('teamsButton')">Enterprise &amp; Teams</button>
                        </div> -->
                    <!-- <div class="d-flex align-items-center gap-2 col-md-8 justify-content-end">
                        <button type="button" class="btn create_block">Individuals</button>
                        <button type="button" class="btn for_teams">For Teams</button>
                            </div> -->

                    <!-- <div class="toggle">
                                <input type="radio" id="boxYearlyDesk" name="pricing" value="yearly" checked="">
                                <label for="boxYearlyDesk" class="yearly">Yearly <span class="yearly-discount">Save 20%</span></label>
                                <input type="radio" id="boxMonthlyDesk" name="pricing" value="monthly">
                                <label for="boxMonthlyDesk" id="monthlyPricing" class="monthly">Monthly</label>
                            </div> -->
                    <div
                        class="text-end mothly-block d-flex align-items-center justify-content-end gap-2 mb-0 pb-0 ms-4">
                        <span>Monthly</span>
                        <!-- <span><label class="switch"> -->
                        <!-- <input type="checkbox" (click)="checkPlan($event)"> -->
                        <mat-slide-toggle class="fs12 fontMed" (change)="checkPlan($event)">
                        </mat-slide-toggle>
                        <!-- <span class="slider round"></span> -->
                        <!-- </label> -->
                        <!-- </span> -->
                        <span>Yearly(20% Off)</span>
                    </div>
                </div>


            </div>

        </div>
    </section>
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="table-responsive">
                    <table class="table custom-table-1">
                        <thead>
                            <tr class="text-center">
                                <th></th>
                                <th>
                                    <div class="table-head-1">
                                        <div>
                                            <img src="assets/images/niha-free.svg" alt="" class="img-fluid">
                                        </div>
                                        <div class="fs-12 header-title">For Individuals</div>
                                    </div>
                                </th>
                                <th>
                                    <div class="table-head-1">
                                        <div>
                                            <img src="assets/images/niha-pro.svg" alt="" class="img-fluid">
                                        </div>
                                        <div class="fs-12 header-title">For Individuals</div>
                                    </div>
                                </th>
                                <th class="position-relative">
                                    <div class="table-head-1">
                                        <div>
                                            <img src="assets/images/niha-pro-plus.svg" alt="" class="img-fluid">
                                        </div>
                                        <div class="fs-12 header-title">For Individuals</div>
                                        <span> Best For Entrepreneurs </span>
                                    </div>
                                </th>
                                <th class="position-relative">
                                    <div class="table-head-1">
                                        <div>
                                            <img src="assets/images/niha-teams.svg" alt="" class="img-fluid">
                                        </div>
                                        <div class="fs-12 header-title">For Organizations</div>
                                        <span>Best For Teams</span>
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="text-center vertical-align-middle">
                                    <h5 class="fw-600">Benefits</h5>
                                </td>
                                <td class="d-flex flex-column">
                                    <div class="text-center">
                                        <h4><strong class="fs-22">Free</strong> <span
                                                class="fw-500 fs-12 text-gray">/Lifetime</span></h4>
                                    </div>
                                    <!-- <button class="btn btn-outline-blue w-100 mt-auto">Create Card</button> -->
                                </td>
                                <td>
                                    <div class="text-center">
                                        <h4 class="mb-3"><strong class="fs-22">${{monthly==true?6:60}}
                                                USD/{{monthly==true?'month': 'year'}}</strong> <span
                                                class="fw-500 fs-12 text-gray">
                                                <!-- /{{monthly==true?'Monthly': 'Yearly'}} -->
                                            </span></h4>
                                        <p class="text-gray fs-12 fw-500 mb-3">Billed annually <br />14 day free
                                            trial included
                                        </p>
                                    </div>
                                    <button class="btn btn-outline-blue w-100" (click)="buyPlan('Pro',monthly)">Start
                                        free trial</button>
                                </td>

                                <td>
                                    <div class="text-center">
                                        <h4 class="mb-3"><strong class="fs-22">${{monthly==true?9:90}}
                                                USD/{{monthly==true?'month': 'year'}}</strong> <span
                                                class="fw-500 fs-12 text-gray">
                                                <!-- /{{monthly==true?'Monthly': 'Yearly'}} -->
                                            </span></h4>
                                        <p class="text-gray fs-12 fw-500 mb-3">Billed annually <br />14 day free
                                            trial included
                                        </p>
                                    </div>
                                    <button class="btn btn-outline-blue w-100"
                                        (click)="buyPlan('Pro-plus',monthly)">Start free trial</button>
                                </td>
                                <td>
                                    <div class="text-center">
                                        <h4 class="mb-3"><strong class="fs-22">Dynamic Pricing</strong></h4>
                                        <p class="text-gray fs-12 fw-500 mb-3">30 Day free trial <br />included</p>
                                    </div>
                                    <button class="btn btn-outline-blue w-100" (click)="bookDemo()">Book a Demo</button>
                                </td>
                            </tr>
                            <tr>
                                <td>#1 Ranked Mobile App</td>
                                <td>Yes</td>
                                <td>Yes</td>
                                <td>Yes</td>
                                <td>Yes</td>
                            </tr>
                            <tr>
                                <td>Number of Digital Business Cards</td>
                                <td>1</td>
                                <td>3</td>
                                <td>5</td>
                                <td>Unlimited Members</td>
                            </tr>
                            <tr>
                                <td>Customizable Profiles & QR Codes</td>
                                <td>Basic</td>
                                <td>Advanced</td>
                                <td>Advanced</td>
                                <td>Advanced</td>
                            </tr>

                            <tr>
                                <td>Digital Card & Niha Device Management</td>
                                <td>Basic</td>
                                <td>Basic</td>
                                <td>Advanced</td>
                                <td>Advanced</td>
                            </tr>

                            <tr>
                                <td>Lead Collection & Management </td>
                                <td>Basic</td>
                                <td>Basic</td>
                                <td>Advanced</td>
                                <td>Advanced</td>
                            </tr>
                            <tr>
                                <td>5000+ CRM Integrations</td>
                                <td></td>
                                <td>Yes</td>
                                <td>Yes</td>
                                <td>Yes</td>
                            </tr>
                            <tr>
                                <td>Business Card Scanner</td>
                                <td></td>
                                <td>Yes</td>
                                <td>Yes</td>
                                <td>Yes</td>
                            </tr>
                            <tr>
                                <td>Custom Email Signatures & Virtual Backgrounds</td>
                                <td>Yes</td>
                                <td>Yes</td>
                                <td>Yes</td>
                                <td>Yes</td>
                            </tr>
                            <tr>
                                <td>Branded Push Notifications</td>
                                <td></td>
                                <td></td>
                                <td>Yes</td>
                                <td>Yes</td>
                            </tr>
                            <tr>
                                <td>Free Niha Products</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td>One per member</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

        </div>
    </div>
    <!-- <form action="/charge" method="post" id="payment-form">
        <div class="form-row">
          <label for="card-element">
            Credit or debit card
          </label>
          <div id="card-element">
           
          </div>
      
       
          <div id="card-errors" role="alert"></div>
        </div>
      
        <button>Submit Payment</button>
      </form> -->
   


    <!-- <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="table-responsive">
                    <table class="table custom-table-1">
                        <thead>
                            <tr class="text-center">
                                <th></th>
                                <th>
                                    <div class="table-head-1">
                                        <div>
                                            <img src="assets/images/niha-free.svg" alt="" class="img-fluid">
                                        </div>
                                        <div class="fs-12 header-title">For Individuals</div>
                                    </div>
                                </th>
                                <th>
                                    <div class="table-head-1">
                                        <div>
                                            <img src="assets/images/niha-pro.svg" alt="" class="img-fluid">
                                        </div>
                                        <div class="fs-12 header-title">For Individuals</div>
                                    </div>
                                </th>
                                <th class="position-relative">
                                    <div class="table-head-1">
                                        <div>
                                            <img src="assets/images/niha-pro-plus.svg" alt="" class="img-fluid">
                                        </div>
                                        <div class="fs-12 header-title">For Individuals</div>
                                        <span> Best For Entrepreneurs </span>
                                    </div>
                                </th>
                                <th class="position-relative">
                                    <div class="table-head-1">
                                        <div>
                                            <img src="assets/images/niha-teams.svg" alt="" class="img-fluid">
                                        </div>
                                        <div class="fs-12 header-title">For Organizations</div>
                                        <span>Best For Teams</span>
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="text-center vertical-align-middle">
                                    <h5 class="fw-600">Benefits</h5>
                                </td>
                                <td class="d-flex flex-column">
                                    <div class="text-center">
                                        <h4><strong class="fs-22">Free</strong> <span
                                                class="fw-500 fs-12 text-gray">/Lifetime</span></h4>
                                    </div>
                                    <button class="btn btn-outline-blue w-100 mt-auto">Create Card</button>
                                </td>
                                <td>
                                    <div class="text-center">
                                        <h4 class="mb-3"><strong class="fs-22">$6 USD/Month</strong> <span
                                                class="fw-500 fs-12 text-gray">/Month</span></h4>
                                        <p class="text-gray fs-12 fw-500 mb-3">Billed annually <br />14 day free
                                            trial included
                                        </p>
                                    </div>
                                    <button class="btn btn-outline-blue w-100">Start free trial</button>
                                </td>
                                <td>
                                    <div class="text-center">
                                        <h4 class="mb-3"><strong class="fs-22">$9 USD/per month</strong> <span
                                                class="fw-500 fs-12 text-gray">/Month</span></h4>
                                        <p class="text-gray fs-12 fw-500 mb-3">Billed annually <br />14 day free
                                            trial included
                                        </p>
                                    </div>
                                    <button class="btn btn-outline-blue w-100">Start free trial</button>
                                </td>
                                <td>
                                    <div class="text-center">
                                        <h4 class="mb-3"><strong class="fs-22">Dynamic Pricing</strong></h4>
                                        <p class="text-gray fs-12 fw-500 mb-3">30 Day free trial <br />included</p>
                                    </div>
                                    <button class="btn btn-outline-blue w-100">Book a Demo</button>
                                </td>
                            </tr>
                            <tr>
                                <td>#1 Ranked Mobile App</td>
                                <td>Yes</td>
                                <td>Yes</td>
                                <td>Yes</td>
                                <td>Yes</td>
                            </tr>
                            <tr>
                                <td>Number of Digital Business Cards</td>
                                <td>1</td>
                                <td>3</td>
                                <td>5</td>
                                <td>Unlimited Members</td>
                            </tr>
                            <tr>
                                <td>Customizable Profiles & QR Codes</td>
                                <td>Basic</td>
                                <td>Advanced</td>
                                <td>Advanced</td>
                                <td>Advanced</td>
                            </tr>
                            <tr>
                                <td>Digital Card & Niha Device Management</td>
                                <td>Basic</td>
                                <td>Basic</td>
                                <td>Advanced</td>
                                <td>Advanced</td>
                            </tr>
                            <tr>
                                <td>Lead Collection & Management </td>
                                <td>Basic</td>
                                <td>Basic</td>
                                <td>Advanced</td>
                                <td>Advanced</td>
                            </tr>
                            <tr>
                                <td>5000+ CRM Integrations</td>
                                <td></td>
                                <td>Yes</td>
                                <td>Yes</td>
                                <td>Yes</td>
                            </tr>
                            <tr>
                                <td>Business Card Scanner</td>
                                <td></td>
                                <td>Yes</td>
                                <td>Yes</td>
                                <td>Yes</td>
                            </tr>
                            <tr>
                                <td>Custom Email Signatures & Virtual Backgrounds</td>
                                <td>Yes</td>
                                <td>Yes</td>
                                <td>Yes</td>
                                <td>Yes</td>
                            </tr>
                            <tr>
                                <td>Branded Push Notifications</td>
                                <td></td>
                                <td></td>
                                <td>Yes</td>
                                <td>Yes</td>
                            </tr>
                            <tr>
                                <td>Free Niha Products</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td>One per member</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
          
        </div>
    </div> -->