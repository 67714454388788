import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AddContentComponent } from '../add-content/add-content.component';

@Component({
  selector: 'app-google-meet',
  templateUrl: './google-meet.component.html',
  styleUrls: ['./google-meet.component.scss']
})
export class GoogleMeetComponent implements OnInit {


  highlyttoggle:boolean = false;
  constructor(public dialog: MatDialog, public dialogRef: MatDialogRef<GoogleMeetComponent>) { }

  ngOnInit(): void {
  }

  closeDialog() {
    this.dialogRef.close();
  }

  backToAddContent(): void {
    this.dialogRef.close();
    this.dialog.open(AddContentComponent, {
      panelClass: ['cmn_modal', 'modal_lg'],
      width: '1066px',
    });
  }

  highlightbtn(event: any) {
    this.highlyttoggle = event.checked;
  }


}
