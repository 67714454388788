<div class="dialog_head position-relative">
    <div class="d-flex align-items-center justify-content-between flex-wrap mb-2">
        <button (click)="backToAddContent()"
            class="btn backbtn lh-1 border-0 fs13 text_secondary px-1 py-0 d-flex align-items-center justify-content-center">
            <img class="me-2" src="assets/images/backbtn.svg">
            <span class="ps-1">Back</span>
        </button>
        <button class="btn p-1 border-0 modal-close position-static d-flex align-items-center justify-content-center"
            (click)="closeDialog()"><img src="assets/images/modal-cross.svg"></button>
    </div>
    <!-- <h5 *ngIf="currentRoute=='/profile' ||currentRoute=='/profile/content' "
        class="mb-0 mt-3 text-center text_secondary fw-bold lh-1">Add
        {{selectedSocialInfo?.name}} link to
        1 profiles</h5> -->

    <h5 class="mb-0 mt-3 text-center text_secondary fw-bold lh-1">Add
        {{selectedSocialInfo?.name}} link to 
        {{selectedMemberCount}} {{selectedMemberCount == 1 ? 'profile': 'profiles'}}</h5>
    <!-- <h5 class="mb-0 mt-3 text-center text_secondary fw  -bold lh-1">Add Custom Link</h5> -->
</div>
<mat-dialog-content class="p-0 m-0" data-simplebar>
    <div class="content_sec">
        <!-- logo sec -->
        <div class="top-sec d-flex align-items-center justify-content-center flex-column">
            <div class="icon-wrap overflow-hidden">
                <img class="img-fluid" *ngIf="!customImage"  src="assets/images/link_icon.png">
                <img class="img-fluid" *ngIf="customImage" [src]="customImage">
                
            </div>
            <p class="fs11 text_gray mt-2 mb-0 lh-1">Custom Link</p>
        </div>
        <div class="padding-content pt-0">
            <!-- form content -->
            <form class="" [formGroup]="customForm">
                <div class="toggle-sec d-flex align-items-center justify-content-between pt-4 pb-3 mb-1">
                    <div class="customSlideToggle rightText">
                        <mat-slide-toggle class="fs11 fontMed" [checked]="highlyttoggle"
                            (change)="highlightbtn($event)" formControlName="highlight">
                            Highlight</mat-slide-toggle>
                    </div>
                    <button
                        class="btn d-flex align-items-center px-4 px-lg-4 h32 btn_xl rounded-pill greyBorder py-0 position-relative">Customize
                        Icon
                        <input type="file" accept="image/png, image/gif, image/jpeg" (change)="fileWrLogo($event)">
                    </button>
                </div>
                <div class="inputwrap position-relative mb-2">
                    <input type="text" class="form-control fs14 h46 inputBg rounded-3 border-0 text-black fontMed"
                        placeholder="Custom Link" formControlName="content">
                </div>
                <div class="inputwrap title d-flex align-items-center justify-content-between h46 inputBg rounded-3">
                    <label class="fs14 text_gray fontMed mb-0">Title</label>
                    <input
                        class="form-control fs14 inputBg h46 rounded-3 pe-0 border-0 text-black fontMed text-end shadow-none"
                        formControlName="title">
                </div>

                <div *ngIf="highlyttoggle==true" class="inputwrap description inputBg rounded-3 mb-4">
                    <label class="fs14 text_gray fontMed mb-2 w-100 lh-1" for="description">Description</label>
                    <textarea class="fs14 inputBg rounded-3 p-0 border-0 text-black w-100 shadow-none" id="description"
                        formControlName="description">Check out my trip to Greece’s new post!</textarea>
                </div>

                <button class="btn commonBtn rounded-pill fs17 w-100 text-white shadow-none" (click)="submitForm()">Save
                    link</button>
            </form>
        </div>
    </div>
</mat-dialog-content>

<!-- Username info popup -->
<div class="modal fade" id="userbameInfo" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
    aria-labelledby="userbameInfoLabel" aria-hidden="true" data-bs-backdrop="false">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content p-0 border-0 rounded-4">
            <div class="p-3 modal-body">
                <h2 class="fs17 fontMed mb-3 text-center">URL of your Website</h2>
                <p class="fs13 mb-0 pb-1 text_secondary text-center">Description...</p>
            </div>

            <div class="modal-footer p-2">
                <button type="button" class="btn btngotit border-0 fs17 fontMed p-0 my-1 mx-auto"
                    data-bs-dismiss="modal" style="color: #498BE5;">Got it!</button>
            </div>
        </div>
    </div>
</div>