// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  baseUrl :'https://api.niha.app/team',
  baseurl:'https://api.niha.app/api',
  GOOGLE_CLIENT_ID : '455661614749-hq3rk66e2c3c50f10ineocivc9klee0t.apps.googleusercontent.com',  

  // baseUrl :'https://niha.app/bapp/team',
  // 'https://niha.app/bapp/team',
  // baseurl:'https://niha.app/bapp/api',
  // 'https://niha.app/bapp/api'
 userImage : 'https://api.niha.app/public/uploads/user_images/',
 socialRectIconsGrey:'https://api.niha.app/public/uploads/service_icons_v2/grey/',
 socialRectIcons: 'https://api.niha.app/public/uploads/service_icons_v2/',     // for rectangle social icons
 socialRoundIcons:'https://api.niha.app/public/uploads/service_icons_v2/round/',    // for round social icons
 qrlBaseUrl:'https://api.niha.app/api/profile',
 activateDeviceBaseUrl:'https://api.niha.app/api/activate_nfc',
 deviceListBaseUrl:'https://api.niha.app/public/uploads/user_qrcode/',
 qrDownloadBaseLink : 'https://api.niha.app/team/download_popcode?user_id=',
 socailIcons : 'https://api.niha.app/public/uploads/user_files/',
 faqUploadBaseUrl:'https://api.niha.app/public/uploads/tutorials/',
 links: 'https://api.niha.app/public/uploads/user_files/',

// baseUrl :'https://staging.niha.app/team',
// baseurl:'https://staging.niha.app/api',
// GOOGLE_CLIENT_ID : '455661614749-hq3rk66e2c3c50f10ineocivc9klee0t.apps.googleusercontent.com',  

// baseUrl :'https://niha.app/bapp/team',
// 'https://niha.app/bapp/team',
// baseurl:'https://niha.app/bapp/api',
// 'https://niha.app/bapp/api'
// userImage : 'https://staging.niha.app/public/uploads/user_images/',
// socialRectIconsGrey:'https://staging.niha.app/public/uploads/service_icons_v2/grey/',
// socialRectIcons: 'https://staging.niha.app/public/uploads/service_icons_v2/',     // for rectangle social icons
// socialRoundIcons:'https://staging.niha.app/public/uploads/service_icons_v2/round/',    // for round social icons
// qrlBaseUrl:'https://staging.niha.app/api/profile',
// activateDeviceBaseUrl:'https://staging.niha.app/api/activate_nfc',
// deviceListBaseUrl:'https://staging.niha.app/public/uploads/user_qrcode/',
// qrDownloadBaseLink : 'https://staging.niha.app/team/download_popcode?user_id=',
// socailIcons : 'https://staging.niha.app/public/uploads/user_files/',
// faqUploadBaseUrl:'https://staging.niha.app/public/uploads/tutorials/',
// links: 'https://staging.niha.app/public/uploads/user_files/'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
