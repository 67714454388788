import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/common/interceptor/api-service/api.service';
import { MessagehandlingService } from 'src/app/common/interceptor/message/messagehandling.service';
import { environment } from 'src/environments/environment';
import { ImageCropperComponent } from '../../shared/image-cropper/image-cropper.component';

@Component({
  selector: 'app-link-edit',
  templateUrl: './link-edit.component.html',
  styleUrls: ['./link-edit.component.scss']
})
export class LinkEditComponent {
  currentRoute: any;
  selectedSocialInfo: any;
  selectedMemberCount: any;
  imageBaseUrl: any
  profileIconArrayLength: any
  userData: any;
  userInfo: any;
  highlyttoggle: boolean = false;
  customForm: FormGroup;
  customLink: any;
  customImage: string=''
  profileData: any=[]
  selectedProfileData: any = [];
  selectedProfileCount: any = 0;
  selectAllCheckbox: boolean = false;
  profileImageUrl: any;
  searchText: any
  securedUrl: any;
  constructor(public dialog: MatDialog, private router: Router, public dialogRef: MatDialogRef<LinkEditComponent>, @Inject(MAT_DIALOG_DATA) public data: { block: any }, private fb: FormBuilder, private api: ApiService, public message: MessagehandlingService,) {
    this.customForm = this.fb.group({
      highlight: data.block.highlight == '' || data.block.highlight == '0' ? false : true,
      custom_icon: this.data.block.custom_icon,
      title: this.data.block.title,
      description: data.block.description,
      content: data.block.content
    })
    if(this.data.block.custom_icon!=''){
    this.customImage = environment.socailIcons + this.data.block.custom_icon
    }else{
      this.customImage==''
    }
  }

  ngOnInit(): void {
    this.getMemberData()
    this.profileImageUrl = environment.userImage
    // this.customLink=this.data.block.custom_icon
    this.selectedSocialInfo = this.data.block;
    this.selectedMemberCount = Number(sessionStorage.getItem('SelectedMembersCount'));
    this.imageBaseUrl = this.api.baseSocialImageUrl
    this.userData = (sessionStorage.getItem('userinformation'))
    this.userInfo = JSON.parse(this.userData)
  }

  closeDialog() {
    this.dialogRef.close();
  }

  // getProfileList() {
  //   let formData = new FormData();
  //   formData.append('user_id', sessionStorage.getItem('SelectedMembersId') || '')
  //   this.api.profileList(formData).subscribe((response: any) => {
  //     if (response.success == 1) {
  //       this.profileData = response.items
  //     }
  //   })
  // }

  getMemberData() {
    let form = new FormData
    this.api.userTeamList(form).subscribe((res: any) => {
      res.items.forEach((element:any) => {
        if(element.member_status!=4){
        this.profileData.push(element)
        }
      });
    })
  }


  stopClose(e: any) {
    e.stopPropagation();
  }

  closeFilter() {
    if (this.selectedProfileData != '') {
      let fd = new FormData
      // fd.append('user_id', sessionStorage.getItem('SelectedMembersId') || '')
      fd.append('user_id', this.selectedProfileData)
      fd.append('type', 'primary')
      fd.append('highlight', this.customForm.value.highlight == '' || this.customForm.value.highlight == false ? '0' : '1')
      fd.append('title', this.customForm.value.title)
      fd.append('content',this.customForm.value.content)
      fd.append('description', this.customForm.value.highlight == true ? this.customForm.value.description : '')
      fd.append('slug', this.selectedSocialInfo?.slug)
      fd.append('custom_icon', this.customLink)
      fd.append('type', 'primary')
      this.api.saveLinksForMultipleProfile(fd).subscribe((res: any) => {
        this.message.sucessMessage(res.msg, 1000)
        $('.filter_dropdown .dropdown-menu, .exportesv .dropdown-menu').removeClass('show');
        this.dialogRef.close()
      })
    }
  }


  checkMat(event: any, index: number, data: any) {
    this.profileData[index].checked = event.checked;
    if (event.checked == true) {
      this.selectedProfileCount = this.selectedProfileCount + 1;
      this.selectedProfileData.push(data.user_id)
      if (this.selectedProfileCount == this.profileData.length) {
        this.selectAllCheckbox = true;
      }
    }
    else {
      this.selectedProfileCount = this.selectedProfileCount - 1;
      this.selectAllCheckbox = false;
      this.selectedProfileData.forEach((element: any, index: number) => {
        if (element == data.user_id) {
          this.selectedProfileData.splice(index, 1)
        }
      })
    }
  }

  selectAllProfile(event: any) {
    this.selectedProfileData = []
    if (event.checked == true) {
      this.profileData.forEach((res: any, index: number) => {
        this.selectedProfileData.push(res.user_id)
        this.profileData[index].checked = true;
        this.selectedProfileCount = this.profileData.length;
      });
    }
    else {
      this.profileData.forEach((res: any, index: number) => {
        this.profileData[index].checked = false;
        this.selectAllCheckbox = false;
        if (this.selectedProfileCount == this.profileData.length) {
          this.selectedProfileData = []
        }
        this.selectedProfileCount = 0;
      });
    }
  }


  backToAddContent(): void {
    this.dialogRef.close();
  }

  fileWrLogo(e: any) {
    this.customLink = e.target.files[0];
    let dialogRef = this.dialog.open(ImageCropperComponent, {
      data: { imageUrl: e },
      panelClass: ['cmn_modal', 'img_croper'],
    });
    setTimeout(() => {
      e.target.value = '';
    }, 0);
    dialogRef.afterClosed().subscribe(res => {
      this.customImage = res.data;
    })
  }


  highlightbtn(event: any) {
    this.highlyttoggle = event.checked;
  }

  deleteIcon(id: any, slug: any) {
    let formData = new FormData()
    formData.append('id', id)
    formData.append('slug', slug)
    this.api.deleteLink(formData).subscribe((res: any) => {
      this.message.sucessMessage(`${this.selectedSocialInfo?.slug} deleted successfully`, 2000)
      this.dialog.closeAll();
    })

  }

  submitForm() {
    // if (
      const url = this.customForm.value.content;
      if (url && url.startsWith('http://')) {
        this.securedUrl = 'https://' + url.substring(7);
      } else if (url && !url.startsWith('https://')) {
        this.securedUrl = 'https://' + url;
      } else {
        this.securedUrl = url;
      }
      let formData = new FormData();
      formData.append('id', this.selectedSocialInfo.id)
      formData.append('highlight', this.customForm.value.highlight == '' || this.customForm.value.highlight == false ? '0' : '1')
      formData.append('title', this.customForm.value.title)
      formData.append('description', this.customForm.value.highlight == true ? this.customForm.value.description : '')
      formData.append('slug', this.selectedSocialInfo?.slug)
      formData.append('custom_icon', this.customLink)
      formData.append('active', '')
      formData.append('type', 'primary')
      formData.append('content',this.securedUrl)
      formData.append('user_id', sessionStorage.getItem('SelectedMembersId') || '')
      formData.append('profile_id', sessionStorage.getItem('SelectedContactId') || '')
      this.api.updateLink(formData).subscribe((res: any) => {
        this.message.sucessMessage(`${this.selectedSocialInfo?.slug} successfully updated to card`, 2000)
        this.dialog.closeAll();
      })
      const currentRoute = this.router.url;

      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        this.router.navigate([currentRoute]); // navigate to same route
      });
    }
  }



