import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ApiService } from 'src/app/common/interceptor/api-service/api.service';
import { MessagehandlingService } from 'src/app/common/interceptor/message/messagehandling.service';

@Component({
  selector: 'app-send-invites',
  templateUrl: './send-invites.component.html',
  styleUrls: ['./send-invites.component.scss']
})
export class SendInvitesComponent implements OnInit {
  MemberData: any = []
  singleSelectMemberData: any = []
  countMember: any;
  selectAllCheckbox: boolean = false

  constructor(public dialog: MatDialog, public dialogRef: MatDialogRef<SendInvitesComponent>, private api: ApiService, private message: MessagehandlingService) { }

  ngOnInit(): void {
    this.getMemberData()
  }

  getMemberData() {
    let form = new FormData()
    this.api.userTeamList(form).subscribe((res: any) => {
      let data = res?.items
      data.forEach((element: any) => {
        if (element.last_login === null) {
          this.MemberData.push(element)
        }
      });
    })
  }



  singleSelectMember(event: any, data: any) {
    if (event.checked == true) {
      this.singleSelectMemberData.push(data.user_id)
      this.countMember = this.countMember + 1;
      if (this.countMember == this.MemberData.length) {
        this.selectAllCheckbox = true;
      }
    }
    else {
      this.countMember = this.countMember - 1;
      this.selectAllCheckbox = false;
      if (this.countMember != this.MemberData.length) {
        this.singleSelectMemberData.forEach((element: any, index: number) => {
          if (element == data.user_id) {
            this.singleSelectMemberData.splice(index, 1)
          }
        });
      }
    }
  }



  selectAllMember(event: any) {
    this.singleSelectMemberData = []
    if (event.checked == true) {
      this.MemberData.forEach((res: any, index: number) => {
        this.singleSelectMemberData.push(res.user_id)
        this.MemberData[index].checked = true;
        this.countMember = this.MemberData.length;
      });
    }
    else {
      this.MemberData.forEach((res: any, index: number) => {
        this.MemberData[index].checked = false;
        this.selectAllCheckbox = false;
        if (this.countMember == this.MemberData.length) {
          this.singleSelectMemberData = []
        }
        this.countMember = 0;
      });
    }

  }

  sendInvite() {
    let formData = new FormData
    formData.append('user_id', this.singleSelectMemberData)
    this.api.resendInvite(formData).subscribe((res: any) => {
      if (res) {
        this.message.sucessMessage(res.msg, 2000)
        this.dialogRef.close()
      }

    })
  }
}
