import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class MessagehandlingService {
  this: any;

  constructor(private _snackBar: MatSnackBar) { }


  sucessMessage(message:any, time:any){
    this._snackBar.open(message, '' , { 
      duration: time,
      horizontalPosition: 'center',
      verticalPosition: 'top',
      panelClass: 'success-snackbar'
    });
  }

  errorMessage(message:any, time:any){
    this._snackBar.open(message, '' , { 
      duration: time,
      horizontalPosition: 'center',
      verticalPosition: 'top',
      panelClass: 'error-snackbar'
    });
  }

  warningMessage(){
    this._snackBar.open('Please choose a account for login', '' , { 
      duration: 1300,
      horizontalPosition: 'center',
      verticalPosition: 'top',
      panelClass: 'waring-snackbar'
    });
  }
}
