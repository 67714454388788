<div class="dialog_head position-relative">
    <div class="d-flex align-items-center justify-content-between flex-wrap">
        <button (click)="backToAddContent()"
            class="btn backbtn lh-1 border-0 fs13 text_secondary px-1 py-0 d-flex align-items-center justify-content-center">
            <img class="me-2" src="assets/images/backbtn.svg">
            <span class="ps-1">Back</span>
        </button>
        <button class="btn p-1 border-0 modal-close position-static d-flex align-items-center justify-content-center"
            (click)="closeDialog()"><img src="assets/images/modal-cross.svg"></button>
    </div>
    <h5 class="mb-0 mt-3 text-center text_secondary fw-bold lh-1">Add Twitch to 39 members live card</h5>
</div>
<mat-dialog-content class="p-0 m-0" data-simplebar>
    <div class="content_sec">
        <!-- logo sec -->
        <div class="top-sec d-flex align-items-center justify-content-center flex-column">
            <div class="icon-wrap"><img src="assets/images/twitch_icon.png"></div>
            <p class="fs11 text_gray mt-2 mb-0 lh-1">Twitch</p>
        </div>
        <div class="padding-content pt-0">
            <!-- form content -->
            <form class="">
                <div class="toggle-sec d-flex align-items-center justify-content-between pt-5 pb-3 mb-1">
                    <div class="customSlideToggle rightText">
                        <mat-slide-toggle class="fs11 fontMed" [checked]="highlyttoggle"
                            (change)="highlightbtn($event)">
                            Highlight</mat-slide-toggle>
                    </div>
                </div>
                <div class="inputwrap position-relative mb-2">
                    <input type="text" class="form-control fs14 h46 inputBg rounded-3 border-0 text-black fontMed"
                        placeholder="Twitch username*">
                </div>

                <div class="inputwrap position-relative mb-2">
                    <input type="text" class="form-control fs14 h46 inputBg rounded-3 border-0 text-black fontMed"
                        placeholder="Link title">
                </div>
                <p  class="test_link cursor_pointer">Test Your Link
                    <span>                        
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="#cdcdcd"><path d="M384 320c-17.67 0-32 14.33-32 32v96H64V160h96c17.67 0 32-14.32 32-32s-14.33-32-32-32L64 96c-35.35 0-64 28.65-64 64V448c0 35.34 28.65 64 64 64h288c35.35 0 64-28.66 64-64v-96C416 334.3 401.7 320 384 320zM488 0H352c-12.94 0-24.62 7.797-29.56 19.75c-4.969 11.97-2.219 25.72 6.938 34.88L370.8 96L169.4 297.4c-12.5 12.5-12.5 32.75 0 45.25C175.6 348.9 183.8 352 192 352s16.38-3.125 22.62-9.375L416 141.3l41.38 41.38c9.156 9.141 22.88 11.84 34.88 6.938C504.2 184.6 512 172.9 512 160V24C512 10.74 501.3 0 488 0z"></path>
                        </svg>
                    </span>
                </p>

                <div *ngIf="highlyttoggle==true" class="inputwrap description inputBg rounded-3 mb-4">
                    <label class="fs14 text_gray fontMed mb-2 w-100 lh-1" for="description">Description</label>
                    <textarea class="fs14 inputBg rounded-3 p-0 border-0 text-black w-100 shadow-none"
                        id="description">Check out my trip to Greece’s new post!</textarea>
                </div>

                <button class="btn commonBtn rounded-pill fs17 w-100 text-white shadow-none disabledBtn">Add
                    link</button>
            </form>
        </div>
    </div>
</mat-dialog-content>
