import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EventService {

  constructor() { }

  private fooSubject = new Subject<any>();
  private filterfooSubject = new Subject<any>();

  publishSomeData(data: any) {
      this.fooSubject.next(data);
  }

  getObservable(): Subject<any> {
      return this.fooSubject;
  }

  filterSomeData(data: any) {
      this.filterfooSubject.next(data);
  }

  filterSgetObservable(): Subject<any> {
      return this.filterfooSubject;
  }

  destroy(){
    return this.filterfooSubject.closed
  }

  publishProfileData(data: any) {
    this.fooSubject.next(data);
}

getProfileObservable(): Subject<any> {
  return this.fooSubject;
}


}
