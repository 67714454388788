import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { BappComponent } from '../bapp.component';

@Component({
  selector: 'app-selectfiletype',
  templateUrl: './selectfiletype.component.html',
  styleUrls: ['./selectfiletype.component.scss']
})
export class SelectfiletypeComponent implements OnInit {
  
  highlyttoggle:boolean = false;
  constructor(public dialog: MatDialog, public dialogRef: MatDialogRef<SelectfiletypeComponent>) { }

  ngOnInit(): void {
  }

  closeDialog() {
    this.dialogRef.close();
  }

  backToFile(): void {
    this.dialogRef.close();
    this.dialog.open(BappComponent, {
      panelClass: ['cmn_modal', 'modal_sm'],
      width: '390px',
    });
  }

}
