import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Location } from '@angular/common';
import { environment } from 'src/environments/environment';

declare const $: any;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],

})
export class AppComponent implements OnInit {
  // title = 'b-team';
  public isVisited = false;
  geturl: string = '';
  currentRoute: any;
  location!: Location;

  constructor(private cdRef: ChangeDetectorRef, private router: Router) {
    // this.location = location;
    if (environment.production) {
      if (location.protocol === 'http:') {
        window.location.href = location.href.replace('http', 'https');
      }
    }
    
    
    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        this.currentRoute = event.url;
      }
    });
  }

  ngOnInit(): void { }

  changeurl(url: string) {
    this.geturl = url;
    this.cdRef.detectChanges();
  }

  checkVisited() {
    $('body').toggleClass('sidebarOpen');
  }

}
