import { ConstantPool } from '@angular/compiler';
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ImageCroppedEvent, base64ToFile } from 'ngx-image-cropper';


@Component({
  selector: 'app-image-cropper',
  templateUrl: './image-cropper.component.html',
  styleUrls: ['./image-cropper.component.scss']
})
export class ImageCropperComponent implements OnInit {
  imageChangedEvent: any;
  croppedImage: any;
  // a!: any
  // b!: any
  ImageBlob!: Blob;
  blobfile!: any;
  type:any
  constructor(@Inject(MAT_DIALOG_DATA) public data: { imageUrl: any , type:any}, private dialogRef: MatDialogRef<ImageCropperComponent>) { }

  ngOnInit(): void {
    this.imageChangedEvent = this.data.imageUrl
    this.type = this.data.type
  }

  async imageCropped(event: ImageCroppedEvent) {

    this.croppedImage = event.base64;
    const base64 = await fetch(this.croppedImage);
    const base64Response = await fetch(`data:image/jpeg;${base64},${this.croppedImage}`);
    const blob = await base64Response.blob();
    this.blobfile = base64ToFile(this.croppedImage);
  }

  cropImage() {
    let i = {
      data: this.croppedImage,
      b: this.blobfile
    }
    this.dialogRef.close(i)
  }

  closeModal() {
    this.dialogRef.close()
  }

}
