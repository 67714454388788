
<div class="container-fluid half_bg">
    <div class="row min-vh-100 align-items-center">
        <div class="col-12 col-md-5">
          <div class="left_column">
            <div class="logoSec d-flex align-items-center">
                <img src="assets/images/logo_login.png" alt="" [routerLink]="['/login']">
                <p class="m-0 ps-4 fontMed">Update password</p> 
            </div>

            <div class="bsForm mt-5 fontMed">
                <form  [formGroup]="changePasswordForm" class="row g-4">
                    <div class="col-12">
                        <label class="form-label small">Password</label>
                        <div class="input_withEye position-relative">
                          <input type="password" class="form-control" [type]="hide ? 'password' : 'text'" formControlName="password" (keydown)="checkEvent($event)" placeholder="Enter password">
                        <mat-icon class="" (click)="hideShowPassword()">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                        </div>
                        <ng-container *ngFor="let error of error_messages.email">
                          <div class="error-message"
                            *ngIf="changePasswordForm.get('password')?.hasError(error.type) && (changePasswordForm.get('password')?.dirty || changePasswordForm.get('password')?.touched)">
                            {{ error.message }}
                          </div>
                        </ng-container>
                      </div>
                    <div class="col-12">
                      <label class="form-label small"> Confirm Password</label>
                      <div class="input_withEye position-relative">
                        <input type="password" class="form-control" [type]="show ? 'password' : 'text'" formControlName="confirmPassword" (keydown)="checkEvent($event)" placeholder="Enter confirm password">
                      <mat-icon class="" (click)="hideConfirmPassword()">{{show ? 'visibility_off' : 'visibility'}}</mat-icon>
                      </div>
                      <ng-container *ngFor="let error of error_messages.email">
                        <div class="error-message"
                          *ngIf="changePasswordForm.get('confirmPassword')?.hasError(error.type) && (changePasswordForm.get('confirmPassword')?.dirty || changePasswordForm.get('confirmPassword')?.touched)">
                          {{ error.message }}
                        </div>
                      </ng-container>
                    </div>
                    <!-- <div class="col-12 text-end mt-1">
                        <label class="form-check-label cursor_pointer fs12" [routerLink]="['/forgot-password']">
                            Forgot password?
                        </label>
                    </div> -->
                    <div class="col-12">
                      <button type="submit" (click)="submitData()"class="btn btn-primary btn_xl rounded-pill w-100 commonBtn">Save new password and sign in</button>
                      <!-- <p class="small text-center pt-4">New to B in Touch? <span class="text_primary cursor_pointer" [routerLink]="['/signup']">Create account</span> </p> -->
                    </div>
                </form>
            </div>
          </div>
        </div>

         <!-- Dashboard screenshot section -->
        <div class="col-12 col-md-7">
          <div class="right_column d-flex justify-content-center flex-column align-items-center">
            <div class="right_container">
              <div class="listing_box ps-3">
                <h3 class="fontMed mb-4">Start your free trial</h3>
                <ul class="list-unstyled fs15">
                  <li>Manage your team's business cards</li>
                  <li>Export leads to CRM</li>
                  <li>Track insights and analytics</li>
                  <li>Communicate with your team via push notifications</li>
                  <li>And much more…</li>
                </ul>
              </div>
              <div class="imgBox mt-4">
                <img src="assets/images/dashboard-screenshot.jpg" class="img-fluid" alt="">
              </div>
            </div>
            
          </div>
        </div>
    </div>
</div>