<div class="dialog_head p-4 pb-0 position-relative">
    <div class="d-flex align-items-center justify-content-between flex-wrap mb-2">
        <button (click)="backToFile()"
            class="btn backbtn lh-1 border-0 fs13 text_secondary px-1 py-0 d-flex align-items-center justify-content-center">
            <img class="me-2" src="assets/images/backbtn.svg">
            <span class="ps-1">Back</span>
        </button>
        <button class="btn p-1 border-0 modal-close position-static d-flex align-items-center justify-content-center"
            (click)="closeDialog()"><img src="assets/images/modal-cross.svg"></button>
    </div>
</div>
<mat-dialog-content class="pt-3 pb-4 px-5 m-0" data-simplebar>
    <div class="contant-sec">
        <h5 class="mb-0 mt-3 text-center text_secondary fw-bold lh-1 mb-2">Add File</h5>
        <button class="cursor_pointer btn w-100">
            <div class="position-relative upload_sec">
                <div class="inner-sec rounded-4 w-100 h-100 text-center upload_btns">
                    <div class="icon-wrap d-flex align-items-center justify-content-center mx-auto">
                        <img class="img-fluid" src="assets/images/add_doc_icon.svg">
                    </div>
                    <h5 class="fs12 fw-bold mb-2 pb-1 text_secondary">Upload Document</h5>
                </div>
                <div class="position-absolute upload_hide">
                    <input class="form-control" type="file" id="formFile">
                </div>
            </div>
        </button>
        <button class="cursor_pointer btn w-100">
            <div class="position-relative upload_sec">
                <div class="inner-sec rounded-4 w-100 h-100 text-center upload_btns">
                    <div class="icon-wrap d-flex align-items-center justify-content-center mx-auto">
                        <img class="img-fluid" src="assets/images/add_media_icon.svg">
                    </div>
                    <h5 class="fs12 fw-bold mb-2 pb-1 text_secondary">Upload Media</h5>
                </div>
                <div class="position-absolute upload_hide">
                    <input class="form-control" type="file" id="formFile">
                </div>
            </div>
        </button>

    </div>
</mat-dialog-content>