import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/common/interceptor/api-service/api.service';
import { MessagehandlingService } from 'src/app/common/interceptor/message/messagehandling.service';
import { AddContentComponent } from '../add-content/add-content.component';
import { WhatsappComponent } from '../whatsapp/whatsapp.component';

@Component({
  selector: 'app-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.scss']
})
export class MessagesComponent implements OnInit {
  
  messageForm: FormGroup;
  highlyttoggle: boolean = false;
  currentRoute: any;
  selectedSocialInfo: any;
  selectedMemberCount: any;
  imageBaseUrl: any
  profileIconArrayLength: any
  userData: any;
  userInfo: any;
  constructor(public dialog: MatDialog, private router: Router, public dialogRef: MatDialogRef<MessagesComponent>, @Inject(MAT_DIALOG_DATA) public data: { block: any }, private fb: FormBuilder, private message: MessagehandlingService, private api: ApiService) {
    this.currentRoute = window.location.pathname
    this.messageForm = this.fb.group({
      highlight: '',
      title: this.data.block.name,
      description: '',
      active: '',
      country_code: '91',
      dial_code: '',
      msg_body:'',
      type:'',
      slug:'',

    })
  }

  ngOnInit(): void {
    this.selectedSocialInfo = this.data.block;
    this.selectedMemberCount = Number(sessionStorage.getItem('SelectedMembersCount'));
    this.imageBaseUrl = this.api.baseSocialImageUrl
    this.userData = (sessionStorage.getItem('userinformation'))
    this.userInfo = JSON.parse(this.userData)
  }

  closeDialog() {
    this.dialogRef.close();
  }

  backToAddContent(): void {
    this.dialogRef.close();
    this.dialog.open(AddContentComponent, {
      panelClass: ['cmn_modal', 'modal_lg'],
      width: '1066px',
    });
  }

  highlightbtn(event: any) {
    this.highlyttoggle = event.checked;
  }

  onCountryChange(data:any) {
    this.messageForm.patchValue({
      country_code:data.dialCode
    })
  }


  numberOnly(event: any): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  submitForm() {
    if (window.location.pathname === '/profile' ||window.location.pathname === '/profile/content' ) {
      this.profileIconArrayLength = sessionStorage.getItem('profileIconArrayLength')
      if (
        !this.messageForm.get('msg_body')?.value ||
        !this.messageForm.get('title')?.value ||
        // !this.messageForm.get('country_code')?.value ||
        !this.messageForm.get('dial_code')?.value
      ) {
        this.message.errorMessage('Please fill in all required fields.', 2000);
        return;
      }
      let formData = new FormData();
      formData.append('highlight', this.messageForm.value.highlight == '' || this.messageForm.value.highlight == false ? '0' : '1')
      formData.append('row_order', this.profileIconArrayLength + 1)
      formData.append('title', this.messageForm.value.title)
      formData.append('description', this.messageForm.value.highlight == true ? this.messageForm.value.description : '')
      formData.append('country_code', this.messageForm.value.country_code)
      formData.append('msg_body', this.messageForm.value.msg_body)
      formData.append('dial_code', this.messageForm.value.dial_code)
      formData.append('slug', this.selectedSocialInfo?.slug)
      formData.append('active', '')
      formData.append('type', 'primary')
      formData.append('user_id', sessionStorage.getItem('SelectedMembersId') || '')
      formData.append('profile_id', sessionStorage.getItem('SelectedContactId') || '')
      this.updateLink(formData)
    }
    if (window.location.pathname === '/members') {
      if (this.selectedMemberCount > 0) {
        if (
          !this.messageForm.get('msg_body')?.value ||
          !this.messageForm.get('title')?.value ||
          // !this.messageForm.get('country_code')?.value ||
          !this.messageForm.get('dial_code')?.value
        ) {
          this.message.errorMessage('Please fill in all required fields.', 2000);
          return;
        }
        let formData = new FormData
        formData.append('highlight', this.messageForm.value.highlight == '' || this.messageForm.value.highlight == false ? '0' : '1')
        formData.append('row_order', this.profileIconArrayLength + 1)
        formData.append('title', this.messageForm.value.title)
        formData.append('description', this.messageForm.value.highlight == true ? this.messageForm.value.description : '')
        formData.append('country_code', this.messageForm.value.country_code)
        formData.append('dial_code', this.messageForm.value.dial_code)
        formData.append('msg_body', this.messageForm.value.msg_body)
        formData.append('slug', this.selectedSocialInfo?.slug)
        formData.append('active', '')
        formData.append('type', 'primary')
        formData.append('user_id', sessionStorage.getItem('userIdArray') || '')
        this.api.saveLinksForMultipleProfile(formData).subscribe((res: any) => {
          this.dialog.closeAll()
          this.message.sucessMessage(res.msg, 2000)
          this.selectedMemberCount == 0
        })
      }
    }
    const currentRoute = this.router.url;
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([currentRoute]); // navigate to same route
    });
  }


  updateLink(formData: any) {
    this.api.updateLink(formData).subscribe((res: any) => {
      this.message.sucessMessage(`${this.selectedSocialInfo?.slug} successfully added to card`, 2000)
      this.dialog.closeAll();
      this.router.navigate(['/profile/content'])
    })
  }

  get content() {
    return this.messageForm.get('dial_code')!.value
  }

  previewLink() {
    window.open(this.selectedSocialInfo?.base_url + this.content)
  }

  telInputObject(obj:any) {
  setTimeout(() => {                          
    obj.setNumber('+'+this.messageForm.value.country_code+this.messageForm.value.dial_code)
  }, 1000);
}
}



