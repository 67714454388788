import { Component, Inject } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/common/interceptor/api-service/api.service';
import { MessagehandlingService } from 'src/app/common/interceptor/message/messagehandling.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-edit-whatsapp',
  templateUrl: './edit-whatsapp.component.html',
  styleUrls: ['./edit-whatsapp.component.scss']
})
export class EditWhatsappComponent {
  whatsappForm: FormGroup;
  highlyttoggle: boolean = false;
  // whatsappForm: FormGroup;
  // highlyttoggle:boolean = false;
  currentRoute: any;
  selectedSocialInfo: any;
  selectedMemberCount: any;
  imageBaseUrl: any
  profileIconArrayLength: any
  userData: any;
  userInfo: any;
  profileData: any=[]
  selectedProfileData: any = [];
  selectedProfileCount: any = 0;
  selectAllCheckbox: boolean = false;
  profileImageUrl: any;
  searchText: any
  dailCode: any;
  constructor(public dialog: MatDialog, public message: MessagehandlingService, private router: Router,
    public dialogRef: MatDialogRef<EditWhatsappComponent>, @Inject(MAT_DIALOG_DATA) public data: { block: any }, private fb: FormBuilder, private api: ApiService,) {
    this.currentRoute = window.location.pathname

    this.whatsappForm = this.fb.group({
      highlight: data.block.highlight == '' || data.block.highlight == '0' ? false : true,
      title: this.data.block.title,
      description: data.block.description,
      active: '',
      country_code: data.block.country_code.toLowerCase(),
      dial_code: data.block.dial_code.replace('+', ''),
      content: data.block.content,
      type: '',
      slug: '',



    })
  }

  ngOnInit(): void {
    this.highlyttoggle=this.data.block.highlight == '' || this.data.block.highlight == '0' ? false : true,
    this.getMemberData()
    this.profileImageUrl = environment.userImage
    this.selectedSocialInfo = this.data.block;
    this.selectedMemberCount = Number(sessionStorage.getItem('SelectedMembersCount'));
    this.imageBaseUrl = this.api.baseSocialImageUrl
    this.userData = (sessionStorage.getItem('userinformation'))
    this.userInfo = JSON.parse(this.userData)
  }

  closeDialog() {
    this.dialogRef.close();
  }


  // getProfileList() {
  //   let formData = new FormData();
  //   formData.append('user_id', sessionStorage.getItem('SelectedMembersId') || '')
  //   this.api.profileList(formData).subscribe((response: any) => {
  //     if (response.success == 1) {
  //       this.profileData = response.items
  //     }
  //   })
  // }

  getMemberData() {
    let form = new FormData
    this.api.userTeamList(form).subscribe((res: any) => {
      res.items.forEach((element:any) => {
        if(element.member_status!=4){
        this.profileData.push(element)
        }
      });
    })
  }

  stopClose(e: any) {
    e.stopPropagation();
  }

  closeFilter() {
    if (this.selectedProfileData != '') {
      if (this.whatsappForm.value.content.charAt() == '+' || this.whatsappForm.value.content.charAt() == 0) {
        let abc = this.whatsappForm.value.content.split(this.whatsappForm.value.dial_code);
        this.dailCode = abc[1]
      } else {
        this.dailCode = this.whatsappForm.value.content
      }
      let fd = new FormData
      // fd.append('user_id', sessionStorage.getItem('SelectedMembersId') || '')
      fd.append('user_id', this.selectedProfileData)
      fd.append('type', 'primary')
      fd.append('highlight', this.whatsappForm.value.highlight == '' || this.whatsappForm.value.highlight == false ? '0' : '1')
      fd.append('title', this.whatsappForm.value.title)
      fd.append('description', this.whatsappForm.value.highlight == true ? this.whatsappForm.value.description : '')
      fd.append('country_code', this.whatsappForm.value.country_code.toUpperCase())
      fd.append('dial_code', '+' + this.whatsappForm.value.dial_code)
      fd.append('content', this.dailCode)
      fd.append('slug', this.selectedSocialInfo?.slug)
      this.api.saveLinksForMultipleProfile(fd).subscribe((res: any) => {
        this.message.sucessMessage(res.msg, 1000)
        $('.filter_dropdown .dropdown-menu, .exportesv .dropdown-menu').removeClass('show');
        this.dialogRef.close()
      })
    }
  }


  checkMat(event: any, index: number, data: any) {
    this.profileData[index].checked = event.checked;
    if (event.checked == true) {
      this.selectedProfileCount = this.selectedProfileCount + 1;
      this.selectedProfileData.push(data.user_id)
      if (this.selectedProfileCount == this.profileData.length) {
        this.selectAllCheckbox = true;
      }
    }
    else {
      this.selectedProfileCount = this.selectedProfileCount - 1;
      this.selectAllCheckbox = false;
      this.selectedProfileData.forEach((element: any, index: number) => {
        if (element == data.user_id) {
          this.selectedProfileData.splice(index, 1)
        }
      })
    }
  }

  selectAllProfile(event: any) {
    this.selectedProfileData = []
    if (event.checked == true) {
      this.profileData.forEach((res: any, index: number) => {
        this.selectedProfileData.push(res.user_id)

        this.profileData[index].checked = true;
        this.selectedProfileCount = this.profileData.length;
      });
    }
    else {
      this.profileData.forEach((res: any, index: number) => {
        this.profileData[index].checked = false;
        this.selectAllCheckbox = false;
        if (this.selectedProfileCount == this.profileData.length) {
          this.selectedProfileData = []
        }
        this.selectedProfileCount = 0;
      });
    }
  }
  back(): void {
    this.dialogRef.close();
  }

  highlightbtn(event: any) {
    this.highlyttoggle = event.checked;
  }

  deleteIcon(id: any, slug: any) {
    let formData = new FormData()
    formData.append('id', id)
    formData.append('slug', slug)
    this.api.deleteLink(formData).subscribe((res: any) => {
      this.message.sucessMessage(`${this.selectedSocialInfo?.slug} deleted successfully`, 2000)
      this.dialog.closeAll();
    })
  }

  onCountryChange(data: any) {
    this.whatsappForm.patchValue({
      country_code: data.dialCode
    })
  }


  numberOnly(event: any): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }

  get content() {
    let showpreview: any;
    const currentContent = this.whatsappForm.value.content;
    const currentDialCode = this.whatsappForm.value.dial_code;

    if (currentContent.startsWith('+') || currentContent.startsWith('0')) {
      showpreview = currentContent;
    } else {
      showpreview = `+${currentDialCode}${currentContent}`;
    }

    return showpreview;
  }

  previewLink() {
    if (this.data.block?.base_url) {
      window.open(this.data.block?.base_url + this.content)
    } else {
      let link: any = this.content.slice(0, 5);
      if (link == "https" || link == "http:") {
        window.open(this.content)
      } else {
        window.open("https://" + this.content)
      }

    }
  }
  submitForm() {
    if (this.whatsappForm.value.highlight && !this.whatsappForm.value.description) {
      this.message.errorMessage('Please provide a description.', 2000);
      return;
    }
    if (this.whatsappForm.value.content.charAt() == '+' || this.whatsappForm.value.content.charAt() == 0) {
      let abc = this.whatsappForm.value.content.split(this.whatsappForm.value.dial_code);
      this.dailCode = abc[1]
    } else {
      this.dailCode = this.whatsappForm.value.content
    }
    if (
      // !this.whatsappForm.get('content')?.value ||
      // !this.whatsappForm.get('title')?.value ||
      // !this.whatsappForm.get('country_code')?.value ||
      !this.whatsappForm.get('dial_code')?.value
    ) {
      this.message.errorMessage('Please fill in all required fields.', 2000);
      return;
    } else {
      let formData = new FormData();
      formData.append('id', this.selectedSocialInfo.id)
      formData.append('highlight', this.whatsappForm.value.highlight == '' || this.whatsappForm.value.highlight == false ? '0' : '1')
      // formData.append('content', this.whatsappForm.value.content)
      formData.append('title', this.whatsappForm.value.title)
      formData.append('description', this.whatsappForm.value.highlight == true ? this.whatsappForm.value.description : '')
      formData.append('country_code', this.whatsappForm.value.country_code.toUpperCase())
      formData.append('dial_code', '+' + this.whatsappForm.value.dial_code)
      formData.append('content', this.dailCode)
      formData.append('slug', this.selectedSocialInfo?.slug)
      formData.append('active', '')
      formData.append('type', 'primary')
      formData.append('user_id', sessionStorage.getItem('SelectedMembersId') || '')
      formData.append('profile_id', sessionStorage.getItem('SelectedContactId') || '')
      this.api.updateLink(formData).subscribe((res: any) => {
        this.message.sucessMessage(`${this.selectedSocialInfo?.slug} successfully updated to card`, 2000)
        this.dialog.closeAll();
      })

      const currentRoute = this.router.url;
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        this.router.navigate([currentRoute]); // navigate to same route
      });
    }
  }

  telInputObject(obj: any) {
    setTimeout(() => {                           //<<<---using ()=> syntax
      obj.setNumber('+' + this.whatsappForm.value.dial_code + this.whatsappForm.value.content)
    }, 1000);
  }
}
