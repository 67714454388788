import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatChipInputEvent } from '@angular/material/chips';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ApiService } from 'src/app/common/interceptor/api-service/api.service';
import { MessagehandlingService } from 'src/app/common/interceptor/message/messagehandling.service';
export interface Fruit {
  name: string;
}

@Component({
  selector: 'app-referral',
  templateUrl: './referral.component.html',
  styleUrls: ['./referral.component.scss']
})
export class ReferralComponent implements OnInit {
  emailForm: FormGroup
  value: any
  constructor(public dialog: MatDialog, public dialogRef: MatDialogRef<ReferralComponent>, private formBuilder: FormBuilder, private api: ApiService, private messageService: MessagehandlingService) {
    this.emailForm = this.formBuilder.group({
      sendEmail: [''],
      message: ['']

    })
  }

  addOnBlur = true;
  readonly separatorKeysCodes = [ENTER, COMMA] as const;
  fruits: Fruit[]=[]

  add(event: MatChipInputEvent): void {
    // const value = (event.value || '').trim();
    const input = event.input;
    this.value = event.value;

    // Add our fruit
    if (this.value) {
      this.fruits.push(this.value);


      // Clear the input value
      event.chipInput!.clear();
    }
  }

  remove(fruit: Fruit): void {
    const index = this.fruits.indexOf(fruit);

    if (index >= 0) {
      this.fruits.splice(index, 1);
    }
  }

  ngOnInit(): void {
  }

  closeDialog() {
    this.dialogRef.close();
  }


  sendemail() {
    if (this.fruits.length != 0 && this.emailForm.value.message != '') {
      let emailVAlueA:any = this.fruits.toString()
      let fd = new FormData
      fd.append('send_to', emailVAlueA)
      fd.append('message', this.emailForm.value.message)
      fd.append('subject', 'get a free month')
      this.api.supportEmail(fd).subscribe((res: any) => {
        this.dialogRef.close()
      })
    }
    else {
      this.messageService.errorMessage('Please fill all details', 1000)
    }
  }
}
