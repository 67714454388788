import { Component } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AppComponent } from 'src/app/app.component';
import { ApiService } from 'src/app/common/interceptor/api-service/api.service';
import { MessagehandlingService } from 'src/app/common/interceptor/message/messagehandling.service';

@Component({
  selector: 'app-update-password',
  templateUrl: './update-password.component.html',
  styleUrls: ['./update-password.component.scss']
})
export class UpdatePasswordComponent {
  hide: boolean = true;
  show: boolean = true;
  changePasswordForm: FormGroup;
  error_messages = {
    'name': [
      { type: 'required', message: 'First Name is required.' },
      { type: 'leadingTrailingSpaces', message: 'Space are not allowed.' },
    ],

    'email': [
      { type: 'required', message: 'Email is required.' },
      { type: 'leadingTrailingSpaces', message: 'Space are not allowed.' },
      { type: 'email', message: 'Please enter a valid email address.' }
    ],

    'password': [
      { type: 'required', message: 'Password is required.' },
      { type: 'leadingTrailingSpaces', message: 'Space are not allowed.' },
      { type: 'minlength', message: 'Password must be of at least 8 characters' }
    ]
  }


  constructor(private app: AppComponent, private api: ApiService, private router: Router, public message: MessagehandlingService) {
    this.changePasswordForm = new FormGroup({
      password: new FormControl('',[Validators.required, this.noLeadingTrailingSpaces()]),
      confirmPassword: new FormControl('',[Validators.required, this.noLeadingTrailingSpaces()])

    })
  }

  noLeadingTrailingSpaces(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (typeof control.value === 'string' && this.hasLeadingTrailingOrConsecutiveSpaces(control.value)) {
        return { leadingTrailingSpaces: true };
      }
      return null;
    };
  }

  hasLeadingTrailingOrConsecutiveSpaces(value: string): boolean {
    if (value.trim() !== value) {
      return true;
    }
    if (value.includes(' ')) {
      return true;
    }

    return false;
  }


  hideShowPassword() {
    this.hide = !this.hide;
  }

  hideConfirmPassword() {
    this.show = !this.show;

  }

  checkEvent(event: any) {
    if (event.target.value == '' && event.which == 32) {
      event.preventDefault();
    }
  }

  submitData() {
    if (this.changePasswordForm.value.password != this.changePasswordForm.value.confirmPassword) {
      this.message.errorMessage('password and confirm password must be the same.', 1000)
    }
    else {
      let fd = new FormData
      fd.append('new_password', this.changePasswordForm.value.password.trim())
      fd.append('confirm_password', this.changePasswordForm.value.confirmPassword.trim())
      this.api.updatePassword(fd).subscribe((res: any) => {
        this.message.sucessMessage(res.msg, 1000)
        this.router.navigate(['/login'])

      })
    }
  }

}
