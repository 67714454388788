import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AddContentComponent } from '../add-content/add-content.component';
import { LinkedinComponent } from '../linkedin/linkedin.component';

@Component({
  selector: 'app-reddit',
  templateUrl: './reddit.component.html',
  styleUrls: ['./reddit.component.scss']
})
export class RedditComponent implements OnInit {

 
  highlyttoggle:boolean = false;
  constructor(public dialog: MatDialog, public dialogRef: MatDialogRef<RedditComponent>) { }

  ngOnInit(): void {
  }

  closeDialog() {
    this.dialogRef.close();
  }

  backToAddContent(): void {
    this.dialogRef.close();
    this.dialog.open(AddContentComponent, {
      panelClass: ['cmn_modal', 'modal_lg'],
      width: '1066px',
    });
  }

  highlightbtn(event: any) {
    this.highlyttoggle = event.checked;
  }
}
