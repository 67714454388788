<div class="dialog_head position-relative">
    <div class="d-flex align-items-center justify-content-between flex-wrap">
        <button (click)="back()"
            class="btn backbtn lh-1 border-0 fs13 text_secondary px-1 py-0 d-flex align-items-center justify-content-center">
            <img class="me-2" src="assets/images/backbtn.svg">
            <span class="ps-1">Back</span>
        </button>
        <button class="btn p-1 border-0 modal-close position-static d-flex align-items-center justify-content-center"
            (click)="closeDialog()"><img src="assets/images/modal-cross.svg"></button>
    </div>
    <h5 class="mb-1 mt-3 text-center text_secondary fw-bold lh-1">{{selectedIconData.name}} link</h5>
    <!-- delete button -->
    <button class="btn deleteBtn border-0" (click)="deleteIcon(selectedIconData.id,selectedIconData.slug)"><img
            src="assets/images/deleteicn.svg" alt=""></button>
</div>
<mat-dialog-content class="p-0 m-0" data-simplebar>
    <div class="content_sec">
        <!-- logo sec -->
        <div class="top-sec d-flex align-items-center justify-content-center flex-column">
            <div class="icon-wrap">
                <img [src]="imageBaseUrl + '/' + selectedIconData?.image" class="imgCls">
            </div>
            <p class="fs11 text_gray mt-2 mb-0 lh-1">{{selectedIconData.name}}</p>
        </div>
        <!-- <div class="padding-content pt-0"> -->
        <!-- form content -->
        <!-- <form class=""> -->

        <!-- Add profile  -->
        <div class="dropdown addprofile_dropdown">
            <button
                class="btn d-flex fs11 align-items-center btn_xl rounded-pill text_secondary greyBorder mx-auto py-2 px-3 mt-2"
                id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                <i class="me-2 d-flex align-items-center justify-content-center">
                    <svg xmlns="http://www.w3.org/2000/svg" width="8.393" height="8.393" viewBox="0 0 8.393 8.393">
                        <path id="Path_34" data-name="Path 34" d="M13.393,9.8H9.8v3.6H8.6V9.8H5V8.6H8.6V5H9.8V8.6h3.6Z"
                            transform="translate(-5 -5)" fill="#4d4e58" />
                    </svg>
                </i>
                Add to other member
            </button>
            <div class="dropdown-menu border-0" aria-labelledby="dropdownMenuButton1" (click)="stopClose($event)">
                <div class="searchbox position-relative mb-3 ">
                    <input type="text" class="form-control rounded-5 border-0 shadow-none" placeholder="Search…"
                        [(ngModel)]="searchText">
                    <span class="searchIcon position-absolute d-flex align-items-center justify-content-center"><img
                            src="assets/images/search_icon.svg" /></span>
                </div>

                <!-- <a class="selectall btn text_secondary ms-3 border-0 fs11 fontMed p-0 mb-3 text-decoration-underline"
                    (click)="selectAllProfile()">Select All</a> -->
                <mat-checkbox [(ngModel)]="selectAllCheckbox" (change)="selectAllProfile($event)"
                    class="custom_check fs12 fontMed letterspacingfull checkBg ms-2">Select All
                </mat-checkbox>
                <div class="scroll_content pt-1 px-3" style="max-height: 177px;" data-simplebar>

                    <div *ngFor="let prof of profileData | custompipe:searchText;let profIndex=index"
                        class="filter-item d-flex align-items-center">
                        <ng-container *ngIf="prof.user_id !== userInfo.user_id">
                        <mat-checkbox class="custom_check flex-shrink-0" [checked]="prof.checked"
                            (change)="checkMat($event,profIndex,prof)"></mat-checkbox>
                        <div
                            class="user-img flex-shrink-0 fs12 font fw-bold text-white d-flex align-items-center justify-content-center">
                            <img *ngIf="prof.photo!=''" class="fit_img" [src]="profileImageUrl+ prof?.photo" alt="">

                            <img *ngIf="prof.photo==''" class="img-fluid" src="assets/images/userdummywithbg.png"
                                alt="">
                        </div>
                        <p class="text_secondary m-0 fs12">{{prof.first_name}}{{prof.last_name!=''? prof.last_name:''}}</p>
                    </ng-container>
                    </div>


                </div>
                <button
                    class="btn commonBtn rounded-pill py-0 fs14 w-100 text-white fw-normal shadow-none disabledBtn mt-3"
                    (click)="closeFilter()" [ngClass]="{'disabledBtn' : selectedProfileCount ==0 }">Add to
                    {{selectedProfileCount}} Member</button>
            </div>
        </div>
        <!-- Add profile end -->

        <!-- <div class="toggle-sec d-flex align-items-center justify-content-between pt-2 mt-1 pb-3 mb-1">
                <div class="customSlideToggle rightText">
                    <mat-slide-toggle class="fs11 fontMed" [checked]="highlyttoggle" (change)="highlightbtn($event)">
                        Highlight</mat-slide-toggle>
                </div>
                <p class="text_primary text-decoration-underline fontMed fs11 mb-0">Preview Link</p>
            </div>
            <div class="inputwrap position-relative mb-2">
                <input type="text" class="form-control fs14 h46 inputBg rounded-3 border-0 text-black fontMed"
                    placeholder="Username without @">
                <img class="icnbtn cursor_pointer" src="assets/images/questionicn.svg" data-bs-toggle="modal"
                    data-bs-target="#userbameInfo">
            </div>
            <p class="fs14 text_gray mb-4 pt-1 ps-2 pb-1">https://www.instagram.com/<span
                    class="text_primary">aspirer.a.inspirer</span></p>

            <div class="inputwrap title d-flex align-items-center justify-content-between h46 inputBg rounded-3">
                <label class="fs14 text_gray fontMed mb-0">Title</label>
                <input
                    class="form-control fs14 inputBg h46 rounded-3 pe-0 border-0 text-black fontMed text-end shadow-none"
                    value="Instagram">
            </div>

            <div *ngIf="highlyttoggle==true" class="inputwrap description inputBg rounded-3 mb-4">
                <label class="fs14 text_gray fontMed mb-2 w-100 lh-1" for="description">Description</label>
                <textarea class="fs14 inputBg rounded-3 p-0 border-0 text-black w-100 shadow-none"
                    id="description">Check out my trip to Greece’s new post!</textarea>
            </div>

            <button class="btn commonBtn rounded-pill fs17 w-100 text-white shadow-none disabledBtn">Update link</button> -->

        <div class="padding-content pt-0">
            <!-- form content -->
            <!-- // added on 25 nov 2022 start -->
            <form [formGroup]="editLinkForm">
                <div class="toggle-sec d-flex align-items-center justify-content-between pt-5 pb-3 mb-1">
                    <div class="customSlideToggle rightText">
                        <!-- [checked]="selectedIconData.highlight==1?true:null" -->
                        <mat-slide-toggle class="fs11 fontMed" formControlName="highlight"
                            *ngIf="selectedIconData.slug!='embedvideo' && selectedIconData.slug!='calendar' && selectedIconData.slug!='cv' && selectedIconData.slug!='link'&& selectedIconData.slug!='file'"
                            [checked]="selectedIconData.highlight==1? true:false" (change)="highlightbtn($event)">
                            Highlight</mat-slide-toggle>
                    </div>
                    <p *ngIf="selectedIconData.slug!='embedvideo'"
                    class="text_primary text-decoration-underline fontMed fs11 mb-0 cursor_pointer"
                    (click)="previewLink()">Preview Link</p>
                    <!-- <p *ngIf="selectedIconData.slug!='embedvideo' && selectedIconData.slug!='calendar' && selectedIconData.slug!='cv' && selectedIconData.slug!='link'&& selectedIconData.slug!='file'"
                        class="text_primary text-decoration-underline fontMed fs11 mb-0 cursor_pointer"
                        (click)="previewLink()">Preview Link</p> -->
                </div>
                <div *ngIf="selectedIconData.slug=='calendar'" class="inputwrap position-relative mb-2">
                    <p>This link will appear on your profile as a button</p>
                </div>
                <div class="inputwrap position-relative mb-2">
                    <input type="text" formControlName="content"
                        class="form-control fs14 h46 inputBg rounded-3 border-0 text-black fontMed pe-5"
                        [placeholder]="selectedIconData?.hints" value={{content}}>
                    <img *ngIf="selectedIconData?.tips!=''|| selectedIconData?.tips!=null&& selectedIconData.slug!='calendar' && selectedIconData.slug!='cv' && selectedIconData.slug!='link'&& selectedIconData.slug!='file'" class="icnbtn cursor_pointer"
                        src="assets/images/questionicn.svg" data-bs-toggle="modal" data-bs-target="#userbameInfo">
                </div>
                <!-- // Dynamic runtime content binding added on 25 nov 2022 start -->
                <!-- <p class="fs14 text_gray mb-4 pt-1 ps-2 pb-1"><span class="text_primary">{{content}}</span></p> -->
                <p *ngIf="selectedIconData.type!='social'" class="fs14 text_gray mb-4 pt-1 ps-2 pb-1 text-truncate">
                    <span class="text_primary">{{content}}</span>
                </p>

                <p *ngIf="selectedIconData.type=='social'" class="fs14 text_gray mb-4 pt-1 ps-2 pb-1 text-truncate">
                    {{selectedIconData?.base_url}}<span class="text_primary">{{content}}</span></p>
                <!-- <p class="fs14 text_gray mb-4 pt-1 ps-2 pb-1 text-break">{{selectedIconData?.base_url}}<span
                    class="text_primary">{{content}}</span></p> -->
                <div class="inputwrap title d-flex align-items-center justify-content-between h46 inputBg rounded-3">
                    <label class="fs14 text_gray fontMed mb-0">Title</label>
                    <input formControlName="title"
                        class="form-control fs14 inputBg h46 rounded-3 pe-0 border-0 text-black fontMed text-end shadow-none"
                        value={{selectedIconData?.title}}>
                </div>
                <div *ngIf="highlyttoggle==true || selectedIconData.highlight==1 && selectedIconData.slug!='embedvideo' && selectedIconData.slug!='calendar' && selectedIconData.slug!='cv' && selectedIconData.slug!='link'&& selectedIconData.slug!='file'"   class="inputwrap description inputBg rounded-3 mb-4">

                <!-- <div *ngIf="selectedIconData.highlight==1 || highlyttoggle==true && selectedIconData.slug!='calendar' && selectedIconData.slug!='cv' && selectedIconData.slug!='link'&& selectedIconData.slug!='file'"
                    class="inputwrap description inputBg rounded-3 mb-4"> -->
                    <label class="fs14 text_gray fontMed mb-2 w-100 lh-1" for="description">Description</label>
                    <textarea formControlName="description"
                        class="fs14 inputBg rounded-3 p-0 border-0 text-black w-100 shadow-none"
                        id="description">Check out my trip to Greece’s new post!</textarea>
                </div>

                <button (click)="submitForm()" type="submit"
                    class="btn  btn-primary commonBtn rounded-pill fs17 w-100  shadow-none"
                    [disabled]="!(editLinkForm.get('highlight')?.dirty|| editLinkForm.get('content')?.dirty|| editLinkForm.get('title')?.dirty || editLinkForm.get('description')?.dirty)">Update
                    link</button>
            </form>
        </div>
        <!-- </form>  -->
        <!-- </div> -->
    </div>
</mat-dialog-content>

<!-- Username info popup -->
<div class="modal fade" id="userbameInfo" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
    aria-labelledby="userbameInfoLabel" aria-hidden="true" data-bs-backdrop="false">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content p-0 border-0 rounded-4">
            <div class="p-3 modal-body">
                <h2 class="fs17 fontMed mb-3 text-center">How to get your {{selectedIconData?.title}} username</h2>
                <p class="fs13 mb-0 pb-1 text_secondary text-center">{{selectedIconData?.tips}}</p>
                <!-- <p class="fs13 mb-0 pb-1 text_secondary text-center">Open {{selectedIconData?.title}} and click the profile icon in the
                    bottom right corner. Your username is at the top.</p> -->
            </div>

            <div class="modal-footer p-2">
                <button type="button" class="btn btngotit border-0 fs17 fontMed p-0 my-1 mx-auto"
                    data-bs-dismiss="modal" style="color: #498BE5;">Got it!</button>
            </div>
        </div>
        <!-- <div class="modal-footer p-2">
            <button type="button" class="btn btngotit border-0 fs17 fontMed p-0 my-1 mx-auto" data-bs-dismiss="modal"
                style="color: #498BE5;">Got it!</button>
        </div> -->
    </div>
</div>