import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/common/interceptor/api-service/api.service';
import { MessagehandlingService } from 'src/app/common/interceptor/message/messagehandling.service';
import { AddContentComponent } from '../add-content/add-content.component';
import * as moment from 'moment';

@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.scss']
})
export class EventsComponent implements OnInit {

  highlyttoggle: boolean = false;
  EventForm: FormGroup
  currentRoute: any = '';
  selectedSocialInfo: any = {};
  selectedMemberCount: number = 0;
  imageBaseUrl: any;
  profileIconArrayLength: any
  userInfo: any = {}
  userData: any

  constructor(private message: MessagehandlingService, private router: Router, private api: ApiService, public dialog: MatDialog, public dialogRef: MatDialogRef<EventsComponent>, public formBuilder: FormBuilder, @Inject(MAT_DIALOG_DATA) public data: { block: any }) {
    this.currentRoute = window.location.pathname

    this.EventForm = this.formBuilder.group({
      title: data.block.name,
      start_date: [''],
      end_date: [''],
      address_line1: [''],
      address_line2: [''],
      postcode: [''],
      city: [''],
      state: [''],
      country: [''],
      url: [''],
      description: [''],
      type: [''],
      id: [''],
      highlight: ['']
    })

  }

  ngOnInit(): void {
    this.selectedSocialInfo = this.data.block;
    this.selectedMemberCount = Number(sessionStorage.getItem('SelectedMembersCount'));
    this.imageBaseUrl = this.api.baseSocialImageUrl
    this.userData = (sessionStorage.getItem('userinformation'))
    this.userInfo = JSON.parse(this.userData)

  }

  closeDialog() {
    this.dialogRef.close();
  }

  backToAddContent(): void {
    this.dialogRef.close();
    this.dialog.open(AddContentComponent, {
      panelClass: ['cmn_modal', 'modal_lg'],
      width: '1066px',
    });
  }

  highlightbtn(event: any) {
    this.highlyttoggle = event.checked;
  }



  submitForm() {
    let stateDate = moment(this.EventForm.value.start_date).format('YYYY-MM-DD hh:mm:ss');
    let endDate = moment(this.EventForm.value.end_date).format('YYYY-MM-DD hh:mm:ss');
    if (window.location.pathname === '/profile' || window.location.pathname === '/profile/content') {
      this.profileIconArrayLength = sessionStorage.getItem('profileIconArrayLength')
      if (
        !this.EventForm.value.start_date ||
        !this.EventForm.value.end_date ||
        !this.EventForm.value.address_line1 ||
        !this.EventForm.value.postcode ||
        !this.EventForm.value.city ||
        !this.EventForm.value.state ||
        !this.EventForm.value.country ||
        !this.EventForm.value.title
      ) {
        this.message.errorMessage('Please fill in all required fields.', 2000);
        return;
      }
      let formData = new FormData();
      formData.append('highlight', this.EventForm.value.highlight == '' || this.EventForm.value.highlight == false ? '0' : '1')
      formData.append('start_date', stateDate)
      formData.append('end_date', endDate)
      formData.append('address_line1', this.EventForm.value.address_line1)
      formData.append('address_line2', this.EventForm.value.address_line2)
      formData.append('postcode', this.EventForm.value.postcode)
      formData.append('city', this.EventForm.value.city)
      formData.append('state', this.EventForm.value.state)
      formData.append('country', this.EventForm.value.country)
      formData.append('url', this.EventForm.value.url)
      formData.append('description', this.EventForm.value.description)
      formData.append('title', this.EventForm.value.title)
      formData.append('slug', this.selectedSocialInfo?.slug)
      formData.append('type', 'primary')
      formData.append('user_id', sessionStorage.getItem('SelectedMembersId') || '')
      formData.append('profile_id', sessionStorage.getItem('SelectedContactId') || '')
      this.updateLink(formData)
      // formData.forEach((val, key) => {
      // })
    }
    if (window.location.pathname === '/members') {
      if (this.selectedMemberCount > 0) {
        if (
          !this.EventForm.value.start_date ||
          !this.EventForm.value.end_date ||
          !this.EventForm.value.address_line1 ||
          !this.EventForm.value.postcode ||
          !this.EventForm.value.city ||
          !this.EventForm.value.state ||
          !this.EventForm.value.country ||
          !this.EventForm.value.title
        ) {
          this.message.errorMessage('Please fill in all required fields.', 2000);
          return;
        }
        let formData = new FormData
        formData.append('highlight', this.EventForm.value.highlight == '' || this.EventForm.value.highlight == false ? '0' : '1')
        formData.append('row_order', this.profileIconArrayLength + 1)
        formData.append('title', this.EventForm.value.title)
        formData.append('start_date', stateDate)
        formData.append('end_date', endDate)
        formData.append('address_line1', this.EventForm.value.address_line1)
        formData.append('address_line2', this.EventForm.value.address_line2)
        formData.append('postcode', this.EventForm.value.postcode)
        formData.append('city', this.EventForm.value.city)
        formData.append('state', this.EventForm.value.state)
        formData.append('country', this.EventForm.value.country)
        formData.append('url', this.EventForm.value.url)
        formData.append('description', this.EventForm.value.description)
        formData.append('slug', this.selectedSocialInfo?.slug)
        formData.append('active', '')
        formData.append('type', 'primary')
        formData.append('user_id', sessionStorage.getItem('userIdArray') || '')
        this.api.saveLinksForMultipleProfile(formData).subscribe((res: any) => {
          this.dialog.closeAll()
          this.message.sucessMessage(res.msg, 2000)
          this.selectedMemberCount == 0
        })
      }
    }
    const currentRoute = this.router.url;
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([currentRoute]); // navigate to same route
    });
  }

  updateLink(formData: any) {
    this.api.saveEvent(formData).subscribe((res: any) => {
      this.message.sucessMessage(`${this.selectedSocialInfo?.slug} successfully added to card`, 2000)
      this.dialog.closeAll();
      this.router.navigate(['/profile/content'])
    })
  }

  get content() {
    return this.EventForm.get('content')!.value
  }

  previewLink() {
    window.open(this.selectedSocialInfo?.base_url + this.content)
  }

}
