<div class="dialog_head position-relative">
    <div class="d-flex align-items-center justify-content-between flex-wrap">
        <button class="btn p-1 border-0 modal-close position-static d-flex align-items-center justify-content-center"
            (click)="closeDialog()"><img src="assets/images/modal-cross.svg"></button>
        <p class="text_primary text-decoration-underline fontMed fs11 mb-0" (click)="previewLink()">Preview Link</p>
    </div>
    <h5 class="mb-1 mt-3 text-center text_secondary fw-bold lh-1">Edit Contact</h5>

</div>
<mat-dialog-content class="p-0 m-0" data-simplebar>
    <div class="content_sec">
        <!-- logo sec -->
        <div class="top-sec d-flex align-items-center justify-content-center flex-column">
            <div class="icon-wrap"><img src="assets/images/contact1.png"></div>
            <p class="fs11 text_gray mt-2 mb-0 lh-1">Contact Card</p>
        </div>
        <div class="padding-content pt-0">

            <p class="mt-5 fs11 text_gray mb-4 text-center">File link will appear on your profile as a button</p>
            <!-- 
            <div class="inputwrap position-relative mb-2">
                <input type="text" class="form-control fs14 h46 inputBg rounded-3 border-0 text-black fontMed"
                    placeholder="Username without @">
                <img class="icnbtn cursor_pointer" src="assets/images/questionicn.svg" data-bs-toggle="modal"
                    data-bs-target="#userbameInfo">
            </div> -->
            <form [formGroup]="editContactForm">

                <div class="inputwrap title d-flex align-items-center justify-content-between h46 inputBg rounded-3">
                    <label class="fs14 text_gray fontMed mb-0">First Name</label>
                    <input formControlName="firstName"
                        class="form-control fs14 inputBg h46 rounded-3 pe-0 border-0 text-black fontMed text-end shadow-none">
                </div>

                <div class="inputwrap title d-flex align-items-center justify-content-between h46 inputBg rounded-3">
                    <label class="fs14 text_gray fontMed mb-0">Last Name / Family Name</label>
                    <input formControlName="lastName"
                        class="form-control fs14 inputBg h46 rounded-3 pe-0 border-0 text-black fontMed text-end shadow-none">
                </div>

                <div
                    class="inputwrap title d-flex align-items-center position-relative justify-content-between h46 inputBg rounded-3">

                    <label class="fs14 text_gray fontMed mb-0">Date of Birth</label>
                    <!-- formControlName="dateOfBirth" -->
                    <mat-form-field class="dob_custom">
                        <input class="fs14 text-black fontMed" matInput [matDatepicker]="picker" formControlName="dateOfBirth">
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker [calendarHeaderComponent]="Dateheader"></mat-datepicker>
                    </mat-form-field>

                    <!-- <div class="example-header">
                        <button mat-icon-button (click)="previousClicked('month')">
                         <mat-icon>keyboard_arrow_left</mat-icon>
                       </button>
                       <span class="example-header-label">{{periodLabel}}</span>
                       <button mat-icon-button (click)="nextClicked('month')">
                         <mat-icon>keyboard_arrow_right</mat-icon>
                       </button>
                     </div> -->
                    <!-- <input formControlName="dateOfBirth" type="date"
                        class="form-control fs14 inputBg h46 rounded-3 pe-0 border-0 text-black fontMed text-end shadow-none"
                        value=""> -->
                    <!-- [matDatepicker]="picker" -->
                    <!-- <mat-datepicker-toggle class="cstmicn" matIconSuffix [for]="picker" >
                        <mat-icon matDatepickerToggleIcon>
                            <img src="assets/images/calendar_today-24px.svg">
                        </mat-icon>
                    </mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker> -->
                    <!-- <input type="text"  matInput placeholder="Choose a date" formControlName="dateOfBirth"  [matDatepicker]="dateOfBirth"  
                            (focus)="dateOfBirth.open()">
                        <mat-datepicker-toggle matSuffix [for]="dateOfBirth"  > -->
                    <!-- <mat-icon matDatepickerToggleIcon (monthSelected)="setMonthAndYear($event, dp)">
                                <img src="assets/images/calendar_today-24px.svg">
                            </mat-icon> -->
                    <!-- <mat-datepicker #dateOfBirth
                            startView="multi-year"
                            (monthSelected)="setMonthAndYear($event, dateOfBirth)"
                            panelClass="example-month-picker">
            </mat-datepicker>
                        </mat-datepicker-toggle>
                        <mat-datepicker #dateOfBirth></mat-datepicker> -->
                </div>



                <div class="inputwrap title d-flex align-items-center justify-content-between h46 inputBg rounded-3">
                    <label class="fs14 text_gray fontMed mb-0">Company/School</label>
                    <input formControlName="company"
                        class="form-control fs14 inputBg h46 rounded-3 pe-0 border-0 text-black fontMed text-end shadow-none"
                        value="">
                </div>

                <div class="inputwrap title d-flex align-items-center justify-content-between h46 inputBg rounded-3">
                    <label class="fs14 text_gray fontMed mb-0">Title/Position</label>
                    <input formControlName="position"
                        class="form-control fs14 inputBg h46 rounded-3 pe-0 border-0 text-black fontMed text-end shadow-none"
                        value="">
                </div>

                <div class="inputwrap title d-flex align-items-center justify-content-between h46 inputBg rounded-3">
                    <label class="fs14 text_gray fontMed mb-0">Phone Number</label>
                    <input formControlName="phoneNumber"
                        class="form-control fs14 inputBg h46 rounded-3 pe-0 border-0 text-black fontMed text-end shadow-none"
                        value="">
                </div>

                <div class="inputwrap title d-flex align-items-center justify-content-between h46 inputBg rounded-3">
                    <label class="fs14 text_gray fontMed mb-0">Work Number</label>
                    <input formControlName="workNumber"
                        class="form-control fs14 inputBg h46 rounded-3 pe-0 border-0 text-black fontMed text-end shadow-none"
                        value="">
                </div>

                <div class="inputwrap title d-flex align-items-center justify-content-between h46 inputBg rounded-3">
                    <label class="fs14 text_gray fontMed mb-0">Email Address</label>
                    <input formControlName="email"
                        class="form-control fs14 inputBg h46 rounded-3 pe-0 border-0 text-black fontMed text-end shadow-none"
                        value="">
                </div>

                <div class="inputwrap title d-flex align-items-center justify-content-between h46 inputBg rounded-3">
                    <label class="fs14 text_gray fontMed mb-0">Website URL</label>
                    <input formControlName="websiteUrl"
                        class="form-control fs14 inputBg h46 rounded-3 pe-0 border-0 text-black fontMed text-end shadow-none"
                        value="">
                </div>

                <div class="inputwrap title d-flex align-items-center justify-content-between h46 inputBg rounded-3">
                    <label class="fs14 text_gray fontMed mb-0">Address Street 1</label>
                    <input formControlName="address"
                        class="form-control fs14 inputBg h46 rounded-3 pe-0 border-0 text-black fontMed text-end shadow-none"
                        value="">
                </div>

                <div class="inputwrap title d-flex align-items-center justify-content-between h46 inputBg rounded-3">
                    <label class="fs14 text_gray fontMed mb-0">Postal Code</label>
                    <input formControlName="postalCode"
                        class="form-control fs14 inputBg h46 rounded-3 pe-0 border-0 text-black fontMed text-end shadow-none"
                        value="">
                </div>

                <div class="inputwrap title d-flex align-items-center justify-content-between h46 inputBg rounded-3">
                    <label class="fs14 text_gray fontMed mb-0">City</label>
                    <input formControlName="city"
                        class="form-control fs14 inputBg h46 rounded-3 pe-0 border-0 text-black fontMed text-end shadow-none"
                        value="">
                </div>

                <div class="inputwrap title d-flex align-items-center justify-content-between h46 inputBg rounded-3">
                    <label class="fs14 text_gray fontMed mb-0">Country</label>
                    <input formControlName="country"
                        class="form-control fs14 inputBg h46 rounded-3 pe-0 border-0 text-black fontMed text-end shadow-none"
                        value="">
                </div>

                <div class="inputwrap description inputBg rounded-3 mb-4">
                    <label class="fs14 text_gray fontMed mb-2 w-100 lh-1" for="description">Note/bio</label>
                    <textarea class="fs14 inputBg rounded-3 p-0 border-0 text-black w-100 shadow-none"
                        formControlName="note" id="description"></textarea>
                </div>

                <button type="submit" class="btn commonBtn rounded-pill fs17 w-100 text-white"
                    (click)="saveContactDetail()">Save Link</button>
            </form>
        </div>
    </div>
</mat-dialog-content>