import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';
import { MessagehandlingService } from '../message/messagehandling.service';
import { Router } from '@angular/router';


@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    public message: MessagehandlingService,
    private spinner: NgxSpinnerService,
    public router:Router
    ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    this.spinner.show();
    return next.handle(request).pipe(catchError(error => {
      this.spinner.hide();
      
      switch (error.http_status) {
        
        case 401:
          if (error.http_status == 401) {
              
            this.message.errorMessage('Another user logged into account via same cred. Please login again', 5000);
            localStorage.removeItem('bteamsToken')
            sessionStorage.removeItem('usertoken')
            this.router.navigate(['login'])
          }
          else {
            // return error
          }
          /********** Auto logout if 401 response returned from api **********/
        //  this.message.errorMessage(error.error.error, 2000)
        // this.message.errorMessage('Security alert', 'You have been logged out for security purpose. Please login again.')
        // this.router.navigate(['']);
        // sessionStorage.clear()
          break;
        case 400:
          /********** handle error msg **********/
          this.message.errorMessage(error.error.error, 2000)
          break;

          case 0:
            /********** If server dosent respond **********/
            this.message.errorMessage("Response is not coming from API", 2000)
            break;

        default:
          /********** Check for other serve-side errors **********/
          this.message.errorMessage(error.error.error, 2000)
          break;
      }
        return throwError(error);
    }));
  }
}
