<section>
  <div class="mainContent">
    <div class="mainContentInner cardOuterPadding h-100">
      <div class="bsForm fontMed dns_card bg-white p-4 rounded27 shadow-sm">
        <form action="" class="row g-4 ng-untouched ng-pristine ng-invalid">
          <div class="col-12">
            <div class="d-flex gap-3 align-items-center mt-2">
              <label>Please Enter Domain Name </label>
              <input type="text" class="form-control ng-untouched ng-pristine bg-white border"
                placeholder="Enter domain name" [(ngModel)]="domainName"  [ngModelOptions]="{standalone: true}">
            </div>
          </div>
          <div class="col-12">
            <h4 class="fw-500 mb-1">How to Point Your Domain/Subdomain to Our Platform</h4>
            <p class="text-gray fs-12 fw-400 mb-4">To ensure that your domain or subdomain directs to our platform,
              follow these steps carefully:</p>

            <h5 class="mb-1">1. Log in to Your Domain Registrar's Website:</h5>
            <p class="text-gray fs-12 fw-400 mb-4">Visit the website of your domain registrar (e.g., GoDaddy, Namecheap,
              Google Domains).
              Log in to your account.</p>

            <h5 class="mb-1">2. Navigate to the DNS Management Section:</h5>
            <p class="text-gray fs-12 fw-400 mb-4">Locate the section labeled "DNS Management," "DNS Settings," "DNS
              Zone File," or similar. This area allows you to manage DNS records for your domain.</p>

            <h5 class="mb-1">3. Add an A Record for Your Domain/Subdomain:</h5>
            <p class="text-gray fs-12 fw-400 mb-4">Choose "A" as the record type. <br>
              If pointing the main domain, leave the "Name" field empty or use "@". <br>
              For subdomains, enter the subdomain name (e.g., "www" for www.example.com) in the "Name" field. <br>
              Input the IP address 35.208.12.231 in the "Value" or "Address" field. <br>
              Save your changes.</p>

            <h5 class="mb-1">4. Verify Domain/Subdomain Pointing:</h5>
            <p class="text-gray fs-12 fw-400 mb-4">Click on the verify button to confirm proper pointing of the domain
              or subdomain.</p>

          </div>
          <div class="col-12">
            <button type="submit" class="btn btn-primary btn_xl rounded-pill w-100 commonBtn mb-2" [disabled]="domainName==''|| userData.domain!=null" (click)="submitDomain()"
             >Verify Domain</button>
          </div>

          <div class="col-12">
            <p class="text-gray fs-12 fw-400 mb-3"><span class="text_red">*</span> Note: It may take up to 24-48 hours
              for the custom domain to fully activate and point to our platform.</p>
            <p class="text-gray fs-12 fw-400 mb-1">If you encounter any issues or require further assistance, please
              contact our support team.<br>
              Thank you for choosing our services!</p>
              
          </div>
        </form>
      </div>
    </div>
  </div>
</section>