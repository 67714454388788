import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { HttpClientModule } from '@angular/common/http';
import { NgxSpinnerModule } from 'ngx-spinner';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { CommonModule } from '@angular/common';
import { AddContentComponent } from './components/modals/add-content/add-content.component';
import { MatDialogModule } from '@angular/material/dialog';
import { AddLinkComponent } from './components/modals/add-link/add-link.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { EditLinkComponent } from './components/modals/edit-link/edit-link.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CustomSearchPipeModule } from './common/pipes/custom-search-pipe/custom-search-pipe.module';
import { ImageCropperComponent } from './components/shared/image-cropper/image-cropper.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { CoreModule } from './common/interceptor/interceptor/core.module';
import { ReferralComponent } from './components/modals/referral/referral.component';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SendInvitesComponent } from './components/modals/send-invites/send-invites.component';
import { EditContactComponent } from './components/modals/edit-contact/edit-contact.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { WhatsappComponent } from './components/modals/whatsapp/whatsapp.component';
import { ContactRedirectComponent } from './components/contact-redirect/contact-redirect.component';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { FacebookComponent } from './components/modals/facebook/facebook.component';
import { LinkedinComponent } from './components/modals/linkedin/linkedin.component';
import { InstagramComponent } from './components/modals/instagram/instagram.component';
import { SnapchatComponent } from './components/modals/snapchat/snapchat.component';
import { TiktokComponent } from './components/modals/tiktok/tiktok.component';
import { TwitterComponent } from './components/modals/twitter/twitter.component';
import { YoutubeComponent } from './components/modals/youtube/youtube.component';
import { PinterestComponent } from './components/modals/pinterest/pinterest.component';
import { BehanceComponent } from './components/modals/behance/behance.component';
import { DevianartComponent } from './components/modals/devianart/devianart.component';
import { TumblrComponent } from './components/modals/tumblr/tumblr.component';
import { DribbbleComponent } from './components/modals/dribbble/dribbble.component';
import { ClubhouseComponent } from './components/modals/clubhouse/clubhouse.component';
import { RedditComponent } from './components/modals/reddit/reddit.component';
import { TwitchComponent } from './components/modals/twitch/twitch.component';
import { QuoraComponent } from './components/modals/quora/quora.component';
import { MeetupComponent } from './components/modals/meetup/meetup.component';
import { FoursquareComponent } from './components/modals/foursquare/foursquare.component';
import { QzoneComponent } from './components/modals/qzone/qzone.component';
import { WeiboComponent } from './components/modals/weibo/weibo.component';
import { RenrenComponent } from './components/modals/renren/renren.component';
import { CalenderComponent } from './components/modals/calender/calender.component';
import { CvComponent } from './components/modals/cv/cv.component';
import { LinkComponent } from './components/modals/link/link.component';
import { UploadfileComponent } from './components/modals/uploadfile/uploadfile.component';
import { ChoosefiletypeComponent } from './components/modals/uploadfile/choosefiletype/choosefiletype.component';
import { BappComponent } from './components/modals/bapp/bapp.component';
import { SelectfiletypeComponent } from './components/modals/bapp/selectfiletype/selectfiletype.component';
import { PhoneComponent } from './components/modals/phone/phone.component';
import { EmailComponent } from './components/modals/email/email.component';
import { ZoomComponent } from './components/modals/zoom/zoom.component';
import { MicrosoftTeamsComponent } from './components/modals/microsoft-teams/microsoft-teams.component';
import { GoogleMeetComponent } from './components/modals/google-meet/google-meet.component';
import { TelegramComponent } from './components/modals/telegram/telegram.component';
import { MessengerComponent } from './components/modals/messenger/messenger.component';
import { FaceTimeComponent } from './components/modals/face-time/face-time.component';
import { SkypeComponent } from './components/modals/skype/skype.component';
import { KikComponent } from './components/modals/kik/kik.component';
import { ViberComponent } from './components/modals/viber/viber.component';
import { AssignSubteamUserComponent } from './components/modals/assign-subteam-user/assign-subteam-user.component';
import { InviteAcceptedComponent } from './components/invite-accepted/invite-accepted.component';
import { NotesComponent } from './components/modals/notes/notes.component';
import { NetworkComponent } from './components/modals/network/network.component';
import { EventsComponent } from './components/modals/events/events.component';
import { Ng2TelInputModule } from 'ng2-tel-input';
import { MessagesComponent } from './components/modals/messages/messages.component';
//import { SubteamModule } from './components/subteam/subteam.module';
import { EditWhatsappComponent } from './components/modals/edit-whatsapp/edit-whatsapp.component';
import { LinkEditComponent } from './components/modals/link-edit/link-edit.component';
import { EditNetworkComponent } from './components/modals/edit-network/edit-network.component';
import { EditEventsComponent } from './components/modals/edit-events/edit-events.component';
import { EditUploadfileComponent } from './components/modals/edit-uploadfile/edit-uploadfile.component';
import { EditBappComponent } from './components/modals/edit-bapp/edit-bapp.component';
import { EditPhoneComponent } from './components/modals/edit-phone/edit-phone.component';
import { EditMessageComponent } from './components/modals/edit-message/edit-message.component';
import { EditNotesComponent } from './components/modals/edit-notes/edit-notes.component';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { UpdatePasswordComponent } from './components/update-password/update-password.component';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { DateheaderComponent } from './components/modals/dateheader/dateheader.component';
import { MatInputModule } from '@angular/material/input';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { NotesPreviewLinkComponent } from './components/notes-preview-link/notes-preview-link.component';
import { SubscriptionPlanComponent } from './components/shared/subscription-plan/subscription-plan.component';
import { SidebarModule } from './components/sidebar/sidebar.module';
import { NihaSubscriptionModule } from './components/niha-subscription/niha-subscription.module';
import { DnsComponent } from './components/dns/dns.component';




@NgModule({
  declarations: [
    AppComponent,
    // SidebarComponent,
    AddContentComponent,
    AddLinkComponent,
    EditLinkComponent,
    ImageCropperComponent,
    ReferralComponent,
    SendInvitesComponent,
    EditContactComponent,
    WhatsappComponent,
    ContactRedirectComponent,
    FacebookComponent,
    LinkedinComponent,
    InstagramComponent,
    SnapchatComponent,
    TiktokComponent,
    TwitterComponent,
    YoutubeComponent,
    PinterestComponent,
    BehanceComponent,
    DevianartComponent,
    TumblrComponent,
    DribbbleComponent,
    ClubhouseComponent,
    RedditComponent,
    TwitchComponent,
    QuoraComponent,
    MeetupComponent,
    FoursquareComponent,
    QzoneComponent,
    WeiboComponent,
    RenrenComponent,
    CalenderComponent,
    CvComponent,
    LinkComponent,
    UploadfileComponent,
    ChoosefiletypeComponent,
    BappComponent,
    SelectfiletypeComponent,
    PhoneComponent,
    EmailComponent,
    AssignSubteamUserComponent,
    InviteAcceptedComponent,
    ZoomComponent,
    MicrosoftTeamsComponent,
    GoogleMeetComponent,
    TelegramComponent,
    MessengerComponent,
    FaceTimeComponent,
    SkypeComponent,
    KikComponent,
    ViberComponent,
    AssignSubteamUserComponent,
    NotesComponent,
    NetworkComponent,
    EventsComponent,
    MessagesComponent,
    EditWhatsappComponent,
    LinkEditComponent,
    EditNetworkComponent,
    EditEventsComponent,
    EditUploadfileComponent,
    EditBappComponent,
    EditPhoneComponent,
    EditMessageComponent,
    EditNotesComponent,
    UpdatePasswordComponent,
    DateheaderComponent,
    ResetPasswordComponent,
    NotesPreviewLinkComponent,
    SubscriptionPlanComponent,
    DnsComponent,


  ],
  imports: [
    BrowserModule,
    CommonModule,
    SidebarModule,
    AppRoutingModule,
    NoopAnimationsModule,
    MatSnackBarModule,
    NgxSpinnerModule,
    BrowserAnimationsModule,
    CoreModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MatDialogModule,
    MatSlideToggleModule,
    MatCheckboxModule,
    MatIconModule,
    FormsModule,
    ClipboardModule,
    ReactiveFormsModule,
    CustomSearchPipeModule,
    ImageCropperModule,
    CoreModule,
    MatChipsModule,
    MatIconModule,
    MatFormFieldModule,
    MatTooltipModule,
    MatDatepickerModule,
    MatNativeDateModule,
    Ng2TelInputModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    Ng2SearchPipeModule,
    MatInputModule,
    NihaSubscriptionModule
    // MatDatepickerModule
    //  SubteamModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent],
  //  providers: [DatePipe],

})
export class AppModule { }
