import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CustompipePipe } from './custompipe.pipe';


@NgModule({
  declarations: [
    CustompipePipe
  ],
  imports: [
    RouterModule
  ],
  exports: [CustompipePipe]
})
export class CustomSearchPipeModule { }
