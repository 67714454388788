<mat-dialog-content class="p-5 pb-4 m-0">



    <p class="fs14 m-0">Are you sure you want to add member to {{subteamData?.name}}?</p>


    <div class="btns-sec modal-footer pt-5 pb-0 text-end border-0">

        <button class="btn whiteBg border rounded-pill py-0 px-4 fs12 fontMed h36 me-2"
            (click)="cancel()">Cancel</button>
        <button class="btn btn-primary commonBtn rounded-pill py-0 fs12 fontMed px-4 h36 ms-1"
            (click)="addSubteamUser()">Add to subteam</button>

    </div>

</mat-dialog-content>