import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConvertcsvjsonService } from 'src/app/common/services/convertcsvjson.service';
import { ImageCropperComponent } from 'src/app/components/shared/image-cropper/image-cropper.component';
import { EditUploadfileComponent } from '../../edit-uploadfile/edit-uploadfile.component';
import { UploadfileComponent } from '../uploadfile.component';


@Component({
  selector: 'app-choosefiletype',
  templateUrl: './choosefiletype.component.html',
  styleUrls: ['./choosefiletype.component.scss']
})
export class ChoosefiletypeComponent implements OnInit {

  highlyttoggle: boolean = false;
  route: any;
  // customLink: any;
  constructor(public dialog: MatDialog, public dialogRef: MatDialogRef<ChoosefiletypeComponent>, public image: ConvertcsvjsonService, @Inject(MAT_DIALOG_DATA) public data: { route: any }) {
    this.route = data.route
  }

  ngOnInit(): void {
  }

  closeDialog() {
    this.dialogRef.close();
  }

  backToFile(): void {
    this.dialogRef.close();
    if (this.route == 'add') {
      this.dialog.open(UploadfileComponent, {
        panelClass: ['cmn_modal', 'modal_sm'],
        width: '390px',
      });
    } else if (this.route == 'edit') {
      this.dialog.open(EditUploadfileComponent, {
        panelClass: ['cmn_modal', 'modal_sm'],
        width: '390px',
      });
    }
  }


  fileWrLogo(e: any) {
    this.image.setImage(e.target.files[0])
    let dialogRef = this.dialog.open(ImageCropperComponent, {
      data: { imageUrl: e },
      panelClass: ['cmn_modal', 'img_croper'],
    });
    setTimeout(() => {
      e.target.value = '';
    }, 0);
    dialogRef.afterClosed().subscribe(res => {
      this.backToFile()
    })
  }

  selectfile(event: any) {
    this.image.setImage(event.target.files[0])
    if (event.target.files && event.target.files[0]) {
      let reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onerror = function (error) {
      };
      this.backToFile()
    }
  }
}
