import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { SidebarComponent } from './sidebar.component';


@NgModule({
    declarations: [SidebarComponent],
    imports: [
        CommonModule,
        MatIconModule,
        MatButtonToggleModule,
        MatCheckboxModule,
        RouterModule
    ],
    providers: [],
     exports:[SidebarComponent]
})
export class SidebarModule { }
